import React, { useState } from "react";
import logo from "../../image/techcherry logo.webp";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from 'react-router-dom';

const MedicalNavbar = () => {
  const [responsivebtn, setresponsivebtn] = useState(false);
  const data = [
    {
      name: "Retail",
      child: [],
    },
    {
      name: "Distribution",
      child: [],
    },
    {
      name: "Manufacture",
      child: [],
    },
    {
      name: "ERP",
      child: [],
    },
    {
      name: "Company",
      child: [],
    },
    {
      name: "Help",
      child: [],
    },
  ];

  const [hoverstate, sethoverstate] = useState(false);

  return (
    <div className="w-full medical-navbar-main fixed h-[60px] z-[20000] top-0 px-[50px] flex items-center   justify-between bg-black text-white">
      {/* ----------logo--------- */}
      <Link to="/"
        className={` w-auto h-full box-border bg-white p-[4px] flex items-center justify-center`}
      >
        <img className="tech-logo-img" src={logo} />
      </Link>

      {/* ----------responsive-btn------------- */}

      {/* ----------ul------------ */}
      <div className={`w-auto flex h-full items-center justify-center`}>
        {/* ------desktop---------- */}
        <ul
          className={`ul-for-medical flex items-center h-full justify-between gap-[20px]`}
        >
          {data.map((item, index) => {
            return (
              <li
                key={index}
                className={`font-[400] h-full relative  px-[4px] flex items-center justify-center text-[1rem]`}
                onMouseLeave={() => sethoverstate(false)}
                onMouseEnter={() => sethoverstate(true)}
              >
                <span
                  className={`p-0 cursor-pointer flex items-center justify-center gap-[3px]`}
                >
                  {item.name}
                  <Icon icon="bxs:down-arrow" />
                </span>

                <div
                  onMouseEnter={() => sethoverstate(true)}
                  onMouseLeave={() => sethoverstate(false)}
                  style={{ display: !hoverstate ? "none" : "block" }}
                  className={` bg-gray-200 left-0 w-screen  absolute top-[60px] h-[300px]`}
                ></div>
              </li>
            );
          })}
        </ul>

        {/* ---------mobile---------- */}
        <ul
          style={{ display: responsivebtn ? "flex" : "none" }}
          className={`ul-for-medical-mobile bg-white flex w-[230px] items-center top-0 h-full justify-between 
            `}
        >
          <li
            className={`font-[400] py-[15px] px-[19px] w-full h-[70px] flex items-center justify-end text-[1rem]`}
          >
            <Icon
              onClick={() => setresponsivebtn(!responsivebtn)}
              icon="akar-icons:cross"
              width="24"
              height="24"
            />
          </li>
          {data.map((item, index) => {
            return (
              <li
                key={index}
                className={`font-[400]  w-full bg-white   px-[4px] flex items-center justify-end text-[1rem] sidebar-medical`}
              >
                <span
                  className={`p-0 cursor-pointer flex items-center justify-center gap-[3px] flex-retail`}
                >
                  {item.name}
                  <Icon icon="bxs:down-arrow" />
                </span>
              </li>
            );
          })}
        </ul>

        <button
          onClick={() => setresponsivebtn(!responsivebtn)}
          className={`responsive-btn`}
        >
          <Icon icon="fe:bar" width="24" height="24" />
        </button>
      </div>
    </div>
  );
};

export default MedicalNavbar;
