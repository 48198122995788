import React from "react";
import { Helmet } from "react-helmet";
import Opticalnav from "../../Optical/Opticalnav";
import Optbiling from "../../Optical/Optbiling";
import Feature from "../../Optical/Optfeature";
import Customer from "../../Optical/Optcustomer";
import Optcard from "../../Optical/Optcard";
import Optform from "../../Optical/Optform";
import Optform1 from "../../Optical/Optform1";
import Optabout from "../../Optical/Optabout";

const Retail = () => {
  return (
    <>
      <div>
        <Helmet>
          {" "}
          {/* used for make a sco friendly */}
          <title>
            Boost Your Retail Sales with YES-BM - Apparel | Footwear |Hardware
            |Grocery | General stores
          </title>
          <meta
            name="description"
            content="Grow your business with YES-BM, the scalable and cloud-based inventory management solution. Access your data anytime, anywhere, and collaborate with your team seamlessly. Our software adapts to your business needs as you grow, ensuring you always have the tools you need to succeed."
          />
          <meta
            name="keywords"
            content="Hardware store inventory software, grocery store inventory software, multi-location inventory, expiration date tracking, supplier management, bulk order discounts, cloud-based inventory management, multi-user access, mobile inventory tracking"
          />
          <link rel="canonical" href="https://yourwebsite.com/" />
          {/* Open Graph and Twitter Meta Tags for Social Sharing */}
          <meta
            property="og:title"
            content="Optical Shop Software | Optical Store Billing software | YES Optical Management Software : Techcherry"
          />
          <meta
            property="og:description"
            content="YES-OPTICAL is a complete Point of Sale (POS) Solution for Optical shop. Our core focus is “Our Client’s” and we use that to guide our day-to-day business decisions. Try us first before......"
          />
          <meta
            property="og:image"
            content="https://yourwebsite.com/thumbnail.jpg"
          />
          <meta property="og:url" content="https://yourwebsite.com/" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Opticalnav NavbarType={"Retail"} />
        <Optbiling />
        <Feature />
        <Customer />
        <Optcard />
        <Optform />
        <Optform1 />
        {/* <Optvideo /> */}
        <Optabout />
      </div>
    </>
  );
};

export default Retail;
