import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./ResCss/resnavbar.css";
import logo from "./ResImage/techcherry logo.webp"
import { Icon } from '@iconify/react/dist/iconify.js';

function Resnavbar() {
  const [showresponsive_btn, setshowresponsive_btn] = useState(true)
  const [openSection, setOpenSection] = useState(null);
  // const [isMobileNavOpen ,setMobileNavOpen] =useState(false)

  //   // Function to toggle dropdown sections
  const toggleDropdown = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const closedrop = () => {
    setOpenSection(null)
  }
  //  const toggleMobileNav =()=>{
  //   setMobileNavOpen(!isMobileNavOpen)
  //  }
  const chef = [

    // { id: 1, name: " Restaurant Menu" },
    // { id: 2, name: "Discounts & Offers" },
    // { id: 3, name: "Online Payment Options" },
    // { id: 4, name: "Ratings & Reviews" },
    // { id: 5, name: "Location-based Search" },
    // { id: 6, name: "Waste Management" },
    // { id: 7, name: "Customer Feedback" },
    // { id: 8, name: "Performance Analytics" },


  ]
  const kot = [
    // { id: 1, name: "Menu Management" },
    // { id: 2, name: "Order Tracking" },
    // { id: 3, name: "Inventory Management" },
    // { id: 4, name: "Recipe Instructions" },
    // { id: 5, name: "Staff Management" },
    // { id: 6, name: "Waste Management" },
    // { id: 7, name: "Customer Feedback" },


  ]

  const captain = [

    // { id: 2, name: "Earnings & Payout" },
    // { id: 3, name: "Status Management" },
    // { id: 4, name: "Navigation & GPS Integration" },
    // { id: 5, name: " In-App Chat & Support" },
    // { id: 6, name: "Emergency/SOS Button" },
    // { id: 7, name: " Notifications for Peak Hours" },

  ]



  return (
    <div>
      <div>
        <div className="res-Navbar" >
          <Link to="/" className="res-logo">
            <img className="tech-logo-img" src={logo} alt="" />
            {/* <b><h2 className='text-red-700 '>Restaurant</h2></b> */}
          </Link>

          <button className={`restront-btn`} onClick={() => setshowresponsive_btn(!showresponsive_btn)}>
            <Icon icon="codicon:three-bars" width="33" height="33" />
          </button>

          {/* <div className="hamburger-icon" onClick={toggleMobileNav}>
          <span className="hamburger"></span>
          <span className="hamburger"></span>
          <span className="hamburger"></span>
        </div> */}

          <div style={{ display: showresponsive_btn ? 'flex' : 'none' }} className={`res-nav   active`}>
            <ul className='res-ul'>
              <li className='py-3'
                onMouseEnter={() => toggleDropdown('POS')}
                onMouseLeave={closedrop}>
                <a >Pos</a>
                {openSection === 'POS' && (
                  <ul className="p-2 bg-base-100 shadow-md pos-dropdown-content" onMouseLeave={closedrop}   >
                    {/* <li><a className="rest-sidebar" href="/about"> <span className='resspan'><img src="https://media.istockphoto.com/id/1224630183/vector/white-paper-or-financial-check-icon-isolated-with-long-shadow-paper-print-check-shop-receipt.jpg?s=612x612&w=0&k=20&c=FT8rPIgJeTuompHNr3YppshjWvelId8DwOdzZDYQ5Z8=" alt="" /></span> <span className='resspan1'>Billing</span></a></li>
                    <li><a className="rest-sidebar" href="/team">  <span className='resspan'><img src="https://i.pinimg.com/564x/e5/80/2b/e5802bdf982846c1f9ddec2e2777f5da.jpg" alt="" /></span> <span className='resspan1'>Inventory</span></a></li>
                    <li><a className="rest-sidebar" href="/projects">  <span className='resspan'><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCZ1dlmHWtumw1IMt-TUdPJEQgnXDW4TvXYg&s" alt="" /></span> <span className='resspan1'>Online Order</span></a></li>
                    <li><a className="rest-sidebar" href="">  <span className='resspan'><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT40AncaVxiV_KjJmERUEm_g31G1W6UfW_jig&s" alt="" /></span>  <span className='resspan1'>Reporting</span></a></li>
                    <li><a className="rest-sidebar" href="">  <span className='resspan'> <img src="https://cdn-icons-png.freepik.com/256/16216/16216423.png?semt=ais_hybrid" alt="" /></span > <span className='resspan1'>Menu</span> </a></li>
                    <li><a className="rest-sidebar" href="">  <span className='resspan'> <img src="https://cdn-icons-png.flaticon.com/512/3908/3908156.png" alt="" /></span> <span className='resspan1'>CRM</span> </a></li> */}
                  </ul>
                )}
              </li>


              <li className='py-3'
                onMouseEnter={() => toggleDropdown('Captain')}
                onMouseLeave={closedrop}>
                <a >Captain App</a>
                {openSection === 'Captain' && (
                  <ul className="p-2 bg-base-100 shadow-md captain-dropdown-content" onMouseLeave={closedrop}   >
                    {captain.map((item) => (
                      <li key={item.id}>
                        <a className="captain-side-drop" href="#"> <span>-</span>{item.name}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              <li className='py-3'
                onMouseEnter={() => toggleDropdown('kot')}
                onMouseLeave={closedrop}><a >KOT Manager App
                </a>
                {openSection === 'kot' && (

                  <ul className="p-2 bg-base-100 shadow-md kot-dropdown-content" onMouseLeave={closedrop}>
                    {kot.map((item) => (
                      <li key={item.id}>
                        <a href="#"> <span>-</span>{item.name}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              <li className='py-3'
                onMouseEnter={() => toggleDropdown('chef')}
                onMouseLeave={closedrop}><a >Chef App</a>
                {openSection === 'chef' && (

                  <ul className="p-2 bg-base-100 shadow-md chef-dropdown-content" onMouseLeave={closedrop}>
                    {chef.map((item) => (
                      <li key={item.id}>
                        <a href="#"> <span>-</span>{item.name}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>


              <li className='py-3'
              ><a >website(Home Delivery, Pickup ,Table)</a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>

  );
}

export default Resnavbar;



