import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import "./dropdownCSS/mission.css"
import card1 from "./image/card1.jpg"
import card2 from "./image/card2.jpg"
import card3 from "./image/card3.jpg"
import card4 from "./image/card4.jpg"
import About from '../About'
import bg from "./image/back.jpg"
import Footer1 from '../Footer1'
import { Helmet } from 'react-helmet'

function Mission() {
   document.title="TechCherry | Vision And Mission "
  return (
    <div>
       <Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>TechCherry | Vision And Mission</title>
        <meta name="description" content="Communicate information in the best way, accessible and avant-garde, with the most modern and advanced technologies focused on project objectives, always in constant communication with the client...." />
        <meta name="keywords" content=" vision of techcherry, mission of techcherry, vision and mision of techcherry" />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="TechCherry | Vision And Mission" />
        <meta property="og:description" content="Communicate information in the best way, accessible and avant-garde, with the most modern and advanced technologies focused on project objectives, always in constant communication with the client...." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Footer/>
      <Navbar/>
      <div>
        <div className="mission">
            <div className="mission1">
               <div className="missi-box bg-blue-900">
                <h1 className='box-heading'>
                  Techcherry's Mission 
                </h1>
                <p>
                To empower businesses and individuals worldwide by developing cutting-edge software solutions that drive productivity, foster creativity, and transform industries, while maintaining a commitment to quality, sustainability, and customer satisfaction.
                </p>
                <ul>
                    <li>Less expenses and ulility Mayor</li>
                    <li>Optimize Resources</li>
                    <li>Motivate identify and correct areas of opportunity</li>
                    <li>Seek ways to transmit and share knowledge</li>
                    <li>Prepare an induction course for new employees</li>
                    <li>Provide the neccessary condition ti achive , meet the challenges</li>
                    <li>Encourage effective communication</li>
                </ul>
               </div>
            </div>
            <div className="mission2">
                <div className="mission2-box">
          <h1 className='mission2-heading'>Techcherry's Vision</h1>
        <p><span className=' miss-p text-black '>To be the global leader in software innovation, creating transformative solutions that inspire progress, enhance connectivity, and drive sustainable growth for individuals and organizations worldwide.</span>
        We are here to enable businesses of all sizes to thrive by delivering integrated software solutions that streamline operations, boost productivity, and drive sustainable growth across industries.
       </p>
       </div>
            </div>
        </div>

        <div className='mission-Card'>
            <img  className=" card-img"src={bg} alt="" />
         <div className='card-box'>
            <div className="card1">
              <img src={card1} alt="" />
              <p>Sharing Information & Knowledge</p>
            </div>
            <div className="card1">
                <img src={card2} alt="" />
                <p>Committed to Client</p>
            </div>
            <div className="card1">
                <img src={card3} alt="" />
                <p>Deliver the right
                solution</p>
            </div>
            <div className="card1">
                <img src={card4} alt="" />
                <p>Open and Honest in Communication</p>
            </div>
         </div>
        </div>
      
      </div>
      <About/>
      <Footer1/>
    </div>
  )
}

export default Mission

