import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import "./css/optical.css";

function Optbiling() {

  const handleWhatsapp = () => {
    const phoneNumber = "9039024004"; // Replace with the desired phone number
    const message = "Hello, I would like to know more about your services."; // Optional message

    // Construct the URL to open WhatsApp with a pre-filled message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open WhatsApp (in a new tab or window)
    window.open(whatsappUrl, "_blank");
  };

  const handleCall = () => {
    const phoneNumber = "9039024004";

    const callUrl = `tel:${phoneNumber}`;

    window.location.href = callUrl;
  };

  return (
    <div className="Billing">
      <div className="billing billing-responsive">
        <img
          src="https://myopticalapp.com/loginpage/common_assets/images/banner1.PNG"
          alt=""
        />
          <div className="bill-box">
            <div className=" input">
              <input className="input-mobile-number" type="number" placeholder="Enter mobile number" />
              <hr />
              <div className="flex-trial-free">
                <button className="get">GET FREE TRIAL</button>
              </div>
            </div>
            <div className="numbers-optical-call">
              <div className="flex-number-whatsapp" onClick={() => handleCall()}>
                <div className="phon-icon-no"><Icon icon="fluent:call-20-filled" /></div>
                <div className="Number-call-marginleft">+91-9039024004</div>
              </div>

              <div className="flex-number-whatsapp" onClick={() => handleWhatsapp()}>
                <div className="whatsapp-icon-no"><Icon icon="logos:whatsapp-icon" /></div>
                <div className="Number-call-marginleft">+91-9039024004</div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Optbiling;
