import React from "react";
import "./Opthalmology.css";
import computerss from "../../image/computer frame/11.png";
import tablet from "../../image/tablet frame/15.png";
import { Icon } from "@iconify/react/dist/iconify.js";

const OpthalmologyBanner = () => {

  const handleWhatsapp = () => {
    const phoneNumber = "9039024004"; // Replace with the desired phone number
    const message = "Hello, I would like to know more about your services."; // Optional message

    // Construct the URL to open WhatsApp with a pre-filled message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open WhatsApp (in a new tab or window)
    window.open(whatsappUrl, "_blank");
  };

  const handleCall = () => {
    const phoneNumber = "9039024004";  
 
    const callUrl = `tel:${phoneNumber}`;
 
    window.location.href = callUrl;
  };

  return (
    <>
      <div
        className={`w-full opthalmology-main flex items-center justify-start pt-[73px] pb-[50px] px-[80px] mt-[62px] bg-white min-h-[300px]`}
      >
        {/* ------------left------------- */}
        <div
          className={`left-side-opthalmology w-[40%] flex flex-col items-center justify-center`}
        >
          <h2 className={``}>
            Integrated Ophthalmology Practice with Optical & Pharmacy
          </h2>

          <p>Manage Business With Professional Software Features</p>
          <div className="flex-optha-box">
            <div className="bill-box bill-box-opthalmology">
              <div className=" input">
                <input className="input-mobile-number" type="number" placeholder="Enter mobile number" />
                <hr />
                <div className="flex-trial-free">
                  <button className="get">GET FREE TRIAL</button>
                </div>
              </div>
            </div>
            <div className="numbers-width-call">
              <div className="flex-number-whatsapp" onClick={() => handleCall()}>
                <div className="phon-icon-no"><Icon icon="fluent:call-20-filled" /></div>
                <div className="Number-call-marginleft">+91-9039024004</div>
              </div>

              <div className="flex-number-whatsapp" onClick={() => handleWhatsapp()}>
                <div className="whatsapp-icon-no"><Icon icon="logos:whatsapp-icon" /></div>
                <div className="Number-call-marginleft">+91-9039024004</div>
              </div>
            </div>


          </div>

          <div className={`bottom-line w-full`}>
            <p>Offline and online with Android & iOS apps.</p>
          </div>
        </div>

        <div className={`right-side-opthalmology`}>
          <div className={`tablet-div`}>
            <img src={tablet} />
            {/* <p>Tablet App</p> */}
          </div>

          <div className={`computer-div`}>
            <img src={computerss} />
            {/* <p>Software with computer</p> */}
          </div>
          {/* <div>
            <img />
            <p>'Website Software & App'</p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default OpthalmologyBanner;
