import React from "react";
import "./BookPocket.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import mobileapp1 from "../image/Mobile app/BILL.webp";
import mobileapp2 from "../image/Mobile app/YB 01.png 02.webp"
import mobileapp3 from "../image/Mobile app/Fram 01.webp"

const BookInPocket = () => {
  const whatsappclick = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=+919039024004&text=Hello, I'm interested in TechCherry's mobile app!`
    );
  };

  const callclick = () => {
    window.open("tel:919039024004");
  };

  return (
    <>
      <div className={`book-pocket`}>
        <div className={`content-section`}>
          {/* -------left---------- */}
          <div className={`left-section`}>
            <button className={`simplyfying`}>Simplifying business</button>

            <h2>Simplifying Business operations and processes</h2>

            <p>
              <span>
                <Icon icon="fluent-mdl2:radio-bullet" width="20" height="20" />
                Create professional invoices in seconds from anywhere, anytime.
              </span>
              <span>
                <Icon icon="fluent-mdl2:radio-bullet" width="20" height="20" />
                Generate instant reports to track your business performance.
              </span>
              <span>
                <Icon icon="fluent-mdl2:radio-bullet" width="20" height="20" />
                Go paperless and save money on printing and storage.
              </span>
              <span>
                <Icon icon="fluent-mdl2:radio-bullet" width="20" height="20" />
                Reduce errors with automated calculations and data entry.
              </span>
              <span>
                <Icon icon="fluent-mdl2:radio-bullet" width="20" height="20" />
                Effortlessly manage your finances with an intuitive and
                easy-to-use interface.
              </span>
            </p>

            <div className={`div-for-button`}>
              <button onClick={() => whatsappclick()}>
                <Icon icon="logos:whatsapp-icon" width="25" height="25" />
                Get Whatsapp
              </button>
              <button onClick={() => callclick()}>
                <Icon icon="fluent:call-48-filled" width="25" height="25" />
                Get Call
              </button>
            </div>
          </div>

          {/* -------------right----------- */}                                                                                                       
          <div className={`right-side`}>
            <div className={`back-image`}>
              <div className={`div-for-all-img`}>
                <img className={`mobile-view-img-1`} src={mobileapp2} />
                <img className={`mobile-view-img-2`} src={mobileapp3} />
                <img className={`mobile-view-img-3`} src={mobileapp1} />
              </div>

              <button className={`bt-icon`}>
                <Icon icon="iconamoon:invoice-bold" />
              </button>

              <div className={`text-content`}>
                <p>All of your data, in one place.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookInPocket;
