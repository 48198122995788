import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import "./MiddleBanner.css";
import imgsrc from "../../image/billing.png";

const Middlebanner = ({
  title = "Billing",
  fetures = [
    {
      title: "Patient Management",
    },
    {
      title: "Prescription Reminder",
    },
    {
      title: "Prescription Image upload at billing time",
    },
    {
      title: "Configurable billing screens",
    },
    {
      title: "Expiry/Near Expiry prompt at the time of billing",
    },
    {
      title: "Cashier/Shift System",
    },
    {
      title: "Multiple Payment Modes (Cash/Credit/Credit Card/Wallets etc.)",
    },
  ],
  image = imgsrc,
  reverse = false,
}) => {
  //   const data = [
  //     {
  //       title: "Patient Management",
  //     },
  //     {
  //       title: "Prescription Reminder",
  //     },
  //     {
  //       title: "Prescription Image upload at billing time",
  //     },
  //     {
  //       title: "Configurable billing screens",
  //     },
  //     {
  //       title: "Expiry/Near Expiry prompt at the time of billing",
  //     },
  //     {
  //       title: "Cashier/Shift System",
  //     },
  //     {
  //       title: "Multiple Payment Modes (Cash/Credit/Credit Card/Wallets etc.)",
  //     },
  //   ];

  return (
    <div
      style={{ flexDirection: !reverse ? "row" : "row-reverse" }}
      className={`banner`}
    >
      {/* --------left---------- */}
      <div className={`left`}>
        <h3>
          <span>
            <Icon icon="raphael:arrowdown" />
          </span>
          {title}
        </h3>
        <ul>
          {fetures.map((item, index) => {
            return (
              <li className={`single-li`}>
                <span>
                  <Icon icon="teenyicons:tick-outline" />
                </span>
                <p>{item.title}</p>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={`right`}>
        <img src={image} />
      </div>
    </div>
  );
};

export default Middlebanner;
