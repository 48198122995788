import React from "react";
import "./Education.css";
import { Helmet } from "react-helmet";
import Opticalnav from "../../Optical/Opticalnav";
import Optbiling from "../../Optical/Optbiling";
import Feature from "../../Optical/Optfeature";
import Customer from "../../Optical/Optcustomer";
import Optcard from "../../Optical/Optcard";
import Optform from "../../Optical/Optform";
import Optform1 from "../../Optical/Optform1";
import Optabout from "../../Optical/Optabout";
import EducationBanner from "./EducationBanner/EducationBanner";
import GrowingSchool from "./GrowingSchool/GrowingSchool";
import FAQ from "./FAQ/FAQ";
import registration from "./../image/registration.png";
import studentportal from "../image/web-touch_17108602.png";
import virtual from "../image/videoconference.png";
import calender from "../image/calendar.png";
import smartsearch from "../image/search-engine.png";
import onlinelibrary from "../image/online-library.png";
import userprofile from "../image/character-design.png";
import Boxes from "./Boxes/Boxes";

const Education = () => {
  const features = [
    "Enquiry/Prospectus Management",
    "Admission Module",
    "Fees/Accounts Module",
    "Report Card Generation",
    "HR and Payroll Management",
    "Library Module",
    "Transport Module",
    "Inventory Management",
    "Hostel Module",
    "Classroom Management",
  ];

  const featuresimg = [
    registration,
    studentportal,
    virtual,
    calender,
    smartsearch,
    onlinelibrary,
    userprofile,
  ];

  return (
    <>
      <div>
        <Helmet>
          {" "}
          {/* used for make a sco friendly */}
          <title>Education Software : Techcherry</title>
          <meta
            name="description"
            content="YES-OPTICAL is a complete Point of Sale (POS) Solution for Optical shop. Our core focus is “Our Client’s” and we use that to guide our day-to-day business decisions. Try us first before......"
          />
          <meta
            name="keywords"
            content="Optical Store Software, Optical Shop Software, optical billing software india,optical software,optical retail software,optical shop software,software for optical shop,optical software for retail stores,optical store software,optic software,optical inventory software,software for optical shops,optical software solutions,optical software programs,optical design software open source,optics software,optical software free,free optical software,optical shop software free download,optical software free download,optical billing software,software for optical shop free download,optical store software free download."
          />
          <link rel="canonical" href="https://yourwebsite.com/" />
          {/* Open Graph and Twitter Meta Tags for Social Sharing */}
          <meta
            property="og:title"
            content="Optical Shop Software | Optical Store Billing software | YES Optical Management Software : Techcherry"
          />
          <meta
            property="og:description"
            content="YES-OPTICAL is a complete Point of Sale (POS) Solution for Optical shop. Our core focus is “Our Client’s” and we use that to guide our day-to-day business decisions. Try us first before......"
          />
          <meta
            property="og:image"
            content="https://yourwebsite.com/thumbnail.jpg"
          />
          <meta property="og:url" content="https://yourwebsite.com/" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Opticalnav NavbarType={"Education"} />
        <EducationBanner />
        <GrowingSchool />
        <Feature features={features} Images={featuresimg} />
        <Boxes />
        {/* <FAQ /> */}
        {/* <Optcard /> */}

        {/* <Optbiling /> */}
        {/* <Customer /> */}
        {/* <Optform /> */}
        {/* <Optform1 /> */}
        {/* <Optvideo /> */}
        <Optabout />
      </div>
    </>
  );
};

export default Education;
