import React from 'react'
import Footer from "./Footer"
import Navbar from "./Navbar"
import "./CSS/refund.css"
function Refund() {
  return (
    <div>
    <Footer/>
    <Navbar/>

    <div className='refund'>
        <h1>Refund and Cancellation policy</h1>
        <div className='refund1'>
          <p className='para1'>
          This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service that you have purchased through the Platform. Under this policy: Cancellations will only be considered if the request is made of placing the order. However,7 days cancellation requests may not be entertained if the orders have been communicated to such sellers /merchant(s) listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an event, you may choose to reject the product at the doorstep. 
          </p>
           
          <p className='para2'>
          TECHCHERRY INFOSYSTEM does not accept cancellation requests for perishable items like
          flowers, eatables, etc. However, the refund / replacement can be made if the user establishes that
          the quality of the product delivered is not good.
          </p>

          <p className='para3'>
          In case of receipt of damaged or defective items, please report to our customer service team. The
            request would be entertained once the seller/ merchant listed on the Platform, has checked and
            determined the same at its own end. This should be reported within of receipt of products.7 days
            In case you feel that the product received is not as shown on the site or as per your expectations,
            you must bring it to the notice of our customer service within of receiving the product. The7 days
            customer service team after looking into your complaint will take an appropriate decision.
          </p>

          <p className='para4'>
          In case of complaints regarding the products that come with a warranty from the manufacturers,
         please refer the issue to them.
          </p>

          <p className='para5'>
          In case of any refunds approved by, it will take for the TECHCHERRY INFOSYSTEM 7 days
        refund to be processed to you.
          </p>
        </div>
    </div>

    <div className='return'>
        <h1>Return Policy</h1>
        <div className='return1'>
            <p className='para1'> We offer refund / exchange within first from the date of your purchase. If have passed7 days 7 days
               since your purchase, you will not be offered a return, exchange or refund of any kind. In order to become eligible for a return or an exchange, (i) the purchased item should be unused and in the same condition as you received it, (ii) the item must have original packaging, (iii) if the item that you purchased on a sale, then the item may not be eligible for a return / exchange. Further, only such items are replaced by us (based on an exchange request), if such items are found defective or damaged.
            </p>    

            <p className='para2'>
            You agree that there may be a certain category of products / items that are exempted from returns or refunds. Such categories of the products would be identified to you at the item of purchase. For exchange / return accepted request(s) (as applicable), once your returned product / item is received and inspected by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further. If the same has been approved after the quality check at our end, your request (i.e. return / exchange) will be processed in accordance with our policies.
            </p>
  
            <h5>Shipping Policy</h5>
            <p className='para3'>
            The orders for the user are shipped through registered domestic courier companies and/or speed post only. Orders are shipped within from the date of the order and/or payment or as per the delivery7 days date agreed at the time of order confirmation and delivering of the shipment, subject to courier company / post office norms. Platform Owner shall not be liable for any delay in delivery by the courier company / postal authority. Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the time of registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case be), the same is not refundable.
            </p>   
        </div>
    </div>
    </div>
  )
}

export default Refund
