import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef } from "react";

const VideoModal = ({ close, videourl }) => {
  const iframe = useRef();

  return (
    <div className={`video-modal`}>
      <iframe
        width="600px"
        height="400px"
        src={`${videourl}?autoplay=1`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        ref={iframe}
      ></iframe>

      {/* ----Span */}
      <span className={`close-span`}>
        <Icon
          onClick={() => close(false)}
          color="red"
          style={{ backgroundColor: "white", borderRadius: "50%" }}
          icon="carbon:close-filled"
          width="32"
          height="32"
        />
      </span>
      <div className={`div-black`} />
    </div>
  );
};

export default VideoModal;
