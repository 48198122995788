import React from "react";
import "./Ophthalmology.css";
import { Helmet } from "react-helmet";
import Opticalnav from "../../Optical/Opticalnav";
import Optbiling from "../../Optical/Optbiling";
import Feature from "../../Optical/Optfeature";
import Customer from "../../Optical/Optcustomer";
import Optcard from "../../Optical/Optcard";
import Optform from "../../Optical/Optform";
import Optform1 from "../../Optical/Optform1";
import Optabout from "../../Optical/Optabout";
import OpthalmologyBanner from "./Opthalmologybanner/OpthalmologyBanner";
import Register from "../image/tablet frame/16.png";
import OptholmologistImg from "../image/tablet frame/15.png";
import CounsollorImg from "../image/tablet frame/14.png";
import OpticalIMG from "../image/computer frame/12.png";

const Ophthalmology = () => {
  const features = [
    "Registration and oppoientment",
    "Ophthalmologists",
    "Optometrist",
    "Counsollers",
    "Optical department",
    "Pharmacy department",
  ];

  const FeaturesImage = [
    Register,
    OptholmologistImg,
    Register,
    CounsollorImg,
    OptholmologistImg,
    OpticalIMG,
  ];

  const frequitioin = [
    {
      quetion: "What is Our Ophthalmology Software?",
      answer:
        "Our Ophthalmology Software is designed to fit how you like to work,It can be adjusted for both individual doctors and the entire eye clinic, Switching from other systems or paper records to Techcherry is easy because you can set it up exactly like you're used to working.",
    },
    {
      quetion: "How can I download and use YES-BM?",
      answer:
        "You can download YES-BM android app directly from google play store. Download and install it, then get registered into it by your Email ID and Mobile number. OR you can get register through www.myopticalapp.com and start using YES-BM in desktop as well, To use other applications of YES-BM you can send enquiry on contact@techcherry.in .",
    },
    {
      quetion: "What kind of business is YES-BM designed for?",
      answer:
        "YES-BM is designed especially for Optical business but it can be used for other business like retail, cloth merchants, restaurants, hardware, grocery store, electronic store, bakeries etc. billing and inventory management purpose as well.",
    },
    {
      quetion:
        " If I want to use YES-BM other than the app, are there any solutions?",
      answer:
        "A: Yes, we have YES-BM for online web based application and for offline desktop based application as well. These two applications also have complete features of YES-BM into them.",
    },
    {
      quetion:
        "If I lost my mobile or laptop/desktop, how do I retrieve my stored data?",
      answer:
        "You can restore your data backup into YES-BM. For that you must have kept backup safely with you either in pen drive or in your drive. It is very easy to take backup of your data from YES-BM app or Software.",
    },
    {
      quetion: "Is there a user manual for YES-BM?",
      answer:
        " YES-BM App is available with reference videos in both Hindi and English language. In each form you have the option of INFO / Question mark symbol, just click on it and you will get the reference video for the particular form how to use it.",
    },
    {
      quetion: " Is my data secure in the YES-BM app?",
      answer:
        "Yes, the data is completely safe and secure as we are not sharing your data anywhere or with any third party. You can keep backup of your data with you in your own drive or any external devices.",
    },
    {
      quetion: "What is the cost of YES-BM?",
      answer:
        " Currently, YES-BM is available in multiple versions, it depends on your choice which version is convenient to you. Please call @ 9039024002/4 for further details.",
    },
  ];

  return (
    <>
      <div>
        <Helmet>
          {" "}
          {/* used for make a sco friendly */}
          <title>Enhance Eye Care with Techcherry - The Complete Ophthalmology Clinic Software</title>
          <meta
            name="description"
            content="Streamline your eye care practice with Techcherry, the comprehensive ophthalmology clinic software. Manage patient appointments, electronic health records, optical inventory, and billing with ease. Improve patient care, enhance efficiency, and grow your practice with our user-friendly and feature-rich solution."
          />
          <meta
            name="keywords"
            content="Ophthalmology clinic software, Eye clinic management software, Optometry software, Eye doctor software, Patient scheduling for eye clinics, Electronic health records (EHR) for ophthalmology, Optical inventory management, Tele-ophthalmology, Eye exam software"
          />
          <link rel="canonical" href="https://yourwebsite.com/" />
          {/* Open Graph and Twitter Meta Tags for Social Sharing */}
          <meta
            property="og:title"
            content="Optical Shop Software | Optical Store Billing software | YES Optical Management Software : Techcherry"
          />
          <meta
            property="og:description"
            content="YES-OPTICAL is a complete Point of Sale (POS) Solution for Optical shop. Our core focus is “Our Client’s” and we use that to guide our day-to-day business decisions. Try us first before......"
          />
          <meta
            property="og:image"
            content="https://yourwebsite.com/thumbnail.jpg"
          />
          <meta property="og:url" content="https://yourwebsite.com/" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Opticalnav NavbarType={"Opthalmology"} />

        {/* -------banner-------- */}
        <OpthalmologyBanner />

        {/* <Optbiling /> */}
        <Feature
          ImageType={"Ophthalmology"}
          Images={FeaturesImage}
          features={features}
        />
        <Customer />
        <Optcard />
        <Optform />
        <Optform1 qution={frequitioin} />
        {/* <Optvideo /> */}
        <Optabout />
      </div>
    </>
  );
};

export default Ophthalmology;
