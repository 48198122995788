import React, { useState } from "react";

function Optform1({
  qution = [
    {
      quetion: "What is YES-BM?",
      answer:
        "YES-BM is an optical’s billing and stock management application. It can be used to mange stock, customer details, patient prescription details, lens prescription and invoicing. It is also available for desktop and web based application",
    },
    {
      quetion: "How can I download and use YES-BM?",
      answer:
        "You can download YES-BM android app directly from google play store. Download and install it, then get registered into it by your Email ID and Mobile number. OR you can get register through www.myopticalapp.com and start using YES-BM in desktop as well, To use other applications of YES-BM you can send enquiry on contact@techcherry.in .",
    },
    {
      quetion: "What kind of business is YES-BM designed for?",
      answer:
        "YES-BM is designed especially for Optical business but it can be used for other business like retail, cloth merchants, restaurants, hardware, grocery store, electronic store, bakeries etc. billing and inventory management purpose as well.",
    },
    {
      quetion:
        " If I want to use YES-BM other than the app, are there any solutions?",
      answer:
        "A: Yes, we have YES-BM for online web based application and for offline desktop based application as well. These two applications also have complete features of YES-BM into them.",
    },
    {
      quetion:
        "If I lost my mobile or laptop/desktop, how do I retrieve my stored data?",
      answer:
        "You can restore your data backup into YES-BM. For that you must have kept backup safely with you either in pen drive or in your drive. It is very easy to take backup of your data from YES-BM app or Software.",
    },
    {
      quetion: "Is there a user manual for YES-BM?",
      answer:
        " YES-BM App is available with reference videos in both Hindi and English language. In each form you have the option of INFO / Question mark symbol, just click on it and you will get the reference video for the particular form how to use it.",
    },
    {
      quetion: " Is my data secure in the YES-BM app?",
      answer:
        "Yes, the data is completely safe and secure as we are not sharing your data anywhere or with any third party. You can keep backup of your data with you in your own drive or any external devices.",
    },
    {
      quetion: "What is the cost of YES-BM?",
      answer:
        " Currently, YES-BM is available in multiple versions, it depends on your choice which version is convenient to you. Please call @ 9039024002/4 for further details.",
    },
  ],
}) {
  const [openSection, setOpenSection] = useState(null);

  // Function to toggle dropdown sections
  const toggleDropdown = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const frequitioin = [
    {
      quetion: "What is YES-BM?",
      answer:
        "YES-BM is an optical’s billing and stock management application. It can be used to mange stock, customer details, patient prescription details, lens prescription and invoicing. It is also available for desktop and web based application",
    },
    {
      quetion: "How can I download and use YES-BM?",
      answer:
        "You can download YES-BM android app directly from google play store. Download and install it, then get registered into it by your Email ID and Mobile number. OR you can get register through www.myopticalapp.com and start using YES-BM in desktop as well, To use other applications of YES-BM you can send enquiry on contact@techcherry.in .",
    },
    {
      quetion: "What kind of business is YES-BM designed for?",
      answer:
        "YES-BM is designed especially for Optical business but it can be used for other business like retail, cloth merchants, restaurants, hardware, grocery store, electronic store, bakeries etc. billing and inventory management purpose as well.",
    },
    {
      quetion:
        " If I want to use YES-BM other than the app, are there any solutions?",
      answer:
        "A: Yes, we have YES-BM for online web based application and for offline desktop based application as well. These two applications also have complete features of YES-BM into them.",
    },
    {
      quetion:
        "If I lost my mobile or laptop/desktop, how do I retrieve my stored data?",
      answer:
        "You can restore your data backup into YES-BM. For that you must have kept backup safely with you either in pen drive or in your drive. It is very easy to take backup of your data from YES-BM app or Software.",
    },
    {
      quetion: "Is there a user manual for YES-BM?",
      answer:
        " YES-BM App is available with reference videos in both Hindi and English language. In each form you have the option of INFO / Question mark symbol, just click on it and you will get the reference video for the particular form how to use it.",
    },
    {
      quetion: " Is my data secure in the YES-BM app?",
      answer:
        "Yes, the data is completely safe and secure as we are not sharing your data anywhere or with any third party. You can keep backup of your data with you in your own drive or any external devices.",
    },
    {
      quetion: "What is the cost of YES-BM?",
      answer:
        " Currently, YES-BM is available in multiple versions, it depends on your choice which version is convenient to you. Please call @ 9039024002/4 for further details.",
    },
  ];

  return (
    <div>
      <>
        <div className="Optform">
          <h3 className="text-center text-4xl">
            <span className="text-amber-700"> Frequently </span>Asked Questions
          </h3>

          <div className="sub-optform">
            {qution.map((item, index) => {
              return (
                <>
                  <div className="optform1">
                    <p
                      className="bg-slate-300 cursor-pointer"
                      onClick={() => toggleDropdown(index)}
                    >
                      <span className="bg-slate-400">+</span> Q: {item.quetion}
                    </p>
                    {openSection === index && (
                      <div className="dropdown-content">
                        <p> A: {item.answer}</p>
                      </div>
                    )}
                  </div>
                </>
              );
            })}

            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("what")}
              >
                <span className="bg-slate-400">+</span> Q: What is YES-BM?
              </p>
              {openSection === "what" && (
                <div className="dropdown-content">
                  <p>
                    {" "}
                    A: YES-BM is an optical’s billing and stock management
                    application. It can be used to mange stock, customer
                    details, patient prescription details, lens prescription and
                    invoicing. It is also available for desktop and web based
                    application
                  </p>
                </div>
              )}
            </div> */}

            {/* Section 2 */}
            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("software")}
              >
                <span className="bg-slate-400">+</span> Q: Some top features of
                YES-BM software?
              </p>
              {openSection === "software" && (
                <div className="dropdown-content">
                  <li>1. Creating Order and Sales Invoices</li>
                  <li>2. Managing inventory and stoc</li>
                  <li>3. Converting Orders to invoices</li>
                  <li>4. Setting alerts and reminders</li>
                  <li>5. Compatibility with android & iOS devices</li>
                  <li>6. Free WhatsApp SMS</li>
                  <li>7. Get feedback using feedback link</li>
                  <li>8. Loyalty & Coupon’s option</li>
                  <li> 9. Cheapest Barcode label price</li>
                  <li>
                    10. Share Pdf download link to customer’s order and invoices
                  </li>
                  <li>11. Workshop Management</li>
                  <li>Separate audit software, 99.9% Stock match Guarantee</li>
                  <li>Sale product in offline and online mode</li>
                  <li>Managing status of product</li>
                  <li>Bulk lens purchase option</li>
                  <li>Multiple bill series option for billing and printing.</li>
                </div>
              )}
            </div> */}

            {/* Section 3 */}
            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("eyeclinic")}
              >
                <span className="bg-slate-400">+</span> Q: How can I download
                and use YES-BM?
              </p>
              {openSection === "eyeclinic" && (
                <div className="dropdown-content">
                  <li>
                    You can download YES-BM android app directly from google
                    play store. Download and install it, then get registered
                    into it by your Email ID and Mobile number. OR you can get
                    register through www.myopticalapp.com and start using YES-BM
                    in desktop as well, To use other applications of YES-BM you
                    can send enquiry on contact@techcherry.in .
                  </li>
                </div>
              )}
            </div> */}

            {/* Section 4 */}
            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("business")}
              >
                <span className="bg-slate-400">+</span> What kind of business is
                YES-BM designed for?
              </p>
              {openSection === "business" && (
                <div className="dropdown-content">
                  <li>
                    YES-BM is designed especially for Optical business but it
                    can be used for other business like retail, cloth merchants,
                    restaurants, hardware, grocery store, electronic store,
                    bakeries etc. billing and inventory management purpose as
                    well.
                  </li>
                </div>
              )}
            </div> */}

            {/* Section 5 */}
            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("solution")}
              >
                <span className="bg-slate-400">+</span> If I want to use YES-BM
                other than the app, are there any solutions?
              </p>
              {openSection === "solution" && (
                <div className="dropdown-content">
                  <li>
                    A: Yes, we have YES-BM for online web based application and
                    for offline desktop based application as well. These two
                    applications also have complete features of YES-BM into
                    them.
                  </li>
                </div>
              )}
            </div> */}

            {/* Section 6 */}
            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("lostData")}
              >
                <span className="bg-slate-400">+</span> Q: If I lost my mobile
                or laptop/desktop, how do I retrieve my stored data?
              </p>
              {openSection === "lostData" && (
                <div className="dropdown-content">
                  <li>
                    A: You can restore your data backup into YES-BM. For that
                    you must have kept backup safely with you either in pen
                    drive or in your drive. It is very easy to take backup of
                    your data from YES-BM app or Software.
                  </li>
                </div>
              )}
            </div> */}

            {/* Section 7 */}
            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("manual")}
              >
                <span className="bg-slate-400">+</span> Is there a user manual
                for YES-BM?
              </p>
              {openSection === "manual" && (
                <div className="dropdown-content">
                  <li>
                    A: YES-BM App is available with reference videos in both
                    Hindi and English language. In each form you have the option
                    of INFO / Question mark symbol, just click on it and you
                    will get the reference video for the particular form how to
                    use it.
                  </li>
                </div>
              )}
            </div> */}

            {/* Section 8 */}
            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("dataSecurity")}
              >
                <span className="bg-slate-400">+</span> Is my data secure in the
                YES-BM app?
              </p>
              {openSection === "dataSecurity" && (
                <div className="dropdown-content">
                  <li>
                    A: Yes, the data is completely safe and secure as we are not
                    sharing your data anywhere or with any third party. You can
                    keep backup of your data with you in your own drive or any
                    external devices.
                  </li>
                </div>
              )}
            </div> */}

            {/* Section 9 */}
            {/* <div className="optform1">
              <p
                className="bg-slate-300 cursor-pointer"
                onClick={() => toggleDropdown("cost")}
              >
                <span className="bg-slate-400">+</span> What is the cost of
                YES-BM?
              </p>
              {openSection === "cost" && (
                <div className="dropdown-content">
                  <li>
                    A: Currently, YES-BM is available in multiple versions, it
                    depends on your choice which version is convenient to you.
                    Please call @ 9039024002/4 for further details.
                  </li>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </>
    </div>
  );
}

export default Optform1;
