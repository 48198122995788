import React from "react";
import "./GrowingSchool.css";
import imgsrc from "../../image/download.png";
import backimage from "../../image/grow.png";

const GrowingSchool = () => {
  const data = [
    {
      mainhead: "Streamline Administrative Tasks with Ease",
      subline:
        "Automate routine tasks like attendance tracking and report generation. Reduce administrative workload and increase efficiency.",
    },
    {
      mainhead: "Enhance Communication and Collaboration",
      subline:
        "Facilitate communication between teachers, students, and parents. Foster collaboration among stakeholders to improve learning outcomes.",
    },
    {
      mainhead: "Empower Data-Driven Decision Making",
      subline:
        "Generate real-time data insights to inform decision making. Monitor student progress and identify areas for improvement.",
    },
  ];

  return (
    <>
      <div className={`growing-school`}>
        {/* -----top--------- */}
        <div className={`top`}>
          <h1>Grow your School with targeted focus on your goals.</h1>
          <p>rest all will be managed by us.</p>
        </div>

        <div className={`growin-school-bottom`}>
          <div className={`bottom-right`}>
            {data.map((item, index) => {
              return (
                <div className={`single-para`}>
                  <img className={`img`} src={imgsrc} />

                  <div className={`right-side`}>
                    <h3>{item.mainhead}</h3>
                    <p>{item.subline}</p>

                    {/* -----------line---------- */}
                    <div className={`line-with-animation`}>
                      <div />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            // style={{ backgroundImage: `url(${backimage})` }}
            className={`bottom-left`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default GrowingSchool;
