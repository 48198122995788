import React, { useEffect, useState } from "react";
import Resnavbar from "../Resnavbar";
import Resbilling from "../Resbilling";
import Resfeature from "../Resfeature";
import Rescustomer from "../Rescustomer";
import Rescard from "../Rescard";
import Resform from "../Resform";
import Resform1 from "../Resform1";
import Resdemo from "../Resdemo";
import Resfooter from "../Resfooter";
import { Helmet } from "react-helmet";
import "../../Optical/css/Responsive.css";

function Reshome() {
  useEffect(() => {
    document.title =
      " Boost Your Restaurant with myrestaurantcrm.com - The All-in-One Restaurant Management Solution";
  }, []);
  
  return (
    <div>
      <Helmet>
        {/* Title tag for SEO */}
        <title>
          Boost Your Restaurant with myrestaurantcrm.com - The All-in-One
          Restaurant Management Solution
        </title>
        <meta
          name="description"
          content="Streamline your restaurant operations with myrestaurantcrm.com, the powerful and user-friendly restaurant management software. From online ordering and table reservations to inventory management and employee scheduling, our solution helps you increase efficiency, improve customer satisfaction, and boost your bottom line."
        />
        <meta
          name="keywords"
          content="Restaurant POS software, Restaurant management software, Online food ordering, Table reservations, Inventory management for restaurants, Employee scheduling, CRM for restaurants, Food delivery software, Kitchen display system"
        />
        <link rel="canonical" href="https://yourwebsite.com/" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta
          property="og:title"
          content="Boost Your Restaurant with myrestaurantcrm.com - The All-in-One Restaurant Management Solution"
        />
        <meta
          property="og:description"
          content="Streamline your restaurant operations with myrestaurantcrm.com, a powerful software solution for restaurant management. Increase efficiency and improve customer satisfaction with our all-in-one platform."
        />
        <meta
          property="og:image"
          content="https://yourwebsite.com/thumbnail.jpg" // Update with the actual image URL
        />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Resnavbar />
      <Resbilling />
      <Resfeature />
      <Rescustomer />
      <Rescard />
      <Resform />
      <Resform1 />
      <Resdemo />
      <Resfooter />
    </div>
  );
}

export default Reshome;
