import { Link } from "react-router-dom";
import "./css/Optnav.css";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import yesbmlogo from "../../src/Component/image/techcherry logo.webp";
import mycampus from "../../src/Component/image/myschoolcampuslogo.png"

function Opticalnav({ NavbarType }) {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [oppentsidebar, setsidebar] = useState(true);

  const closeLoginForm = () => {
    setIsLoginOpen(false); // Close the login form when the cross icon is clicked
  };

  const handleLoginClick = () => {
    setIsLoginOpen(!isLoginOpen);
    //   document.getElementById('loginForm').scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    if (isLoginOpen) {
      const loginFormElement = document.getElementById("loginForm");
      if (loginFormElement) {
        loginFormElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isLoginOpen]); // Trigger the scroll when isLoginOpen changes to true

  return (
    <>
      <div>
        <div className={`opt-Navbar`}>
          <Link to="/" className="opt-logo flex items-center justify-center">
            <img
              // src={yesbmlogo}
              src={NavbarType === "Education" ? mycampus : yesbmlogo}
              className={`w-[39px] !h-auto mr-[2px] tech-logo-img`}
              alt="logo"
            />
            {/* <span className={`techcherry-name p-0 font-[600] text-[20px]`}>
              <span className={`!p-0 text-red-500`}>T</span>ech
              <span className={`!p-0 text-red-500`}>C</span>
              herry
            </span> */}
          </Link>
          <div className="opt-nav">
            {/* ---OPTICAL */}
            {NavbarType == "OPTICAL" && (
              <ul
                style={{ display: oppentsidebar ? "flex" : "none" }}
                className="opt-ul"
              >
                <li>
                  <a href="#retail">OPTICAL RETAIL/CHAIN STORE</a>
                </li>
                <li>
                  <a href="#len">Pharmacy</a>
                </li>
                <li>
                  <a href="#slider">EYE CLINIC</a>
                </li>
                <li>
                  <a a href="#opti">
                    OPTI COMMERCE
                  </a>
                </li>
                <li>
                  <a a href="#demo">
                    DEMO VIDEO
                  </a>
                </li>
                <li href="#loginForm" onClick={handleLoginClick}>
                  <a a href="#p">
                    LOGIN
                  </a>
                </li>
              </ul>
            )}

            {NavbarType == "Education" && (
              <ul
                style={{ display: oppentsidebar ? "flex" : "none" }}
                className="opt-ul"
              >
                <li>
                  <a href="#retail">HOME</a>
                </li>
                <li className={`link-features relative`}>
                  <a className={`features-ancor`} href="#len">
                    PRODUCTS
                  </a>

                  <div
                    className={` fetures-dropdown absolute flex-col flex items-center justify-start`}
                  >
                    {/* <a>Online Admission Management</a>
                    <a>Fees Management System</a> */}
                    <a>YES - IM ERP </a>
                    <a>Admission Management Software</a>
                    <a>Fees Management Software</a>
                    <a>Report Card Generation Software</a>
                    <a>Library Management Software</a>
                    <a>Transport Management Software</a>
                    <a>Online Learning Management Software</a>
                    <a>Payroll Software</a>
                    <a>Store Management Software</a>
                    <a>Accounts Management Software</a>
                    <a>Hostel Management Software</a>
                    <a>WhatsApp Business Panel for Education</a>
                    <a>WhWebsite </a>
                    <a>Mobile App  </a>
                    
                  </div>
                </li>
                <li>
                  <a href="https://www.youtube.com/@techcherryinfosystem2223/videos">DEMO VIDEOS</a>
                </li>
                <li>
                  <Link to="/contact" a href="#opti">
                    CONTACT US
                  </Link>
                </li>
              </ul>
            )}

            {NavbarType == "Opthalmology" && (
              <ul
                style={{ display: oppentsidebar ? "flex" : "none" }}
                className="opt-ul"
              >
                <li>
                  <a href="#slider">Ophthalmologists</a>
                </li>
                <li>
                  <a href="#retail">OPTICAL RETAIL/CHAIN STORE</a>
                </li>
                <li>
                  <a href="#len">Pharmacy</a>
                </li>
                <li>
                  <a a href="#opti">
                    OPTI COMMERCE
                  </a>
                </li>
                <li>
                  <a a href="#demo">
                    DEMO VIDEO
                  </a>
                </li>
                <li href="#loginForm" onClick={handleLoginClick}>
                  <a a href="#p">
                    LOGIN
                  </a>
                </li>
              </ul>
            )}

            {NavbarType == "Retail" && (
              <ul
                style={{ display: oppentsidebar ? "flex" : "none" }}
                className="opt-ul"
              >
                <li>
                  <a href="#slider">Retail</a>
                </li>
                <li>
                  <a href="#retail">RETAIL/CHAIN STORE</a>
                </li>
                <li>
                  <a href="#len">Pharmacy</a>
                </li>
                <li>
                  <a a href="#opti">
                    OPTI COMMERCE
                  </a>
                </li>
                <li>
                  <a a href="#demo">
                    DEMO VIDEO
                  </a>
                </li>
                <li href="#loginForm" onClick={handleLoginClick}>
                  <a a href="#p">
                    LOGIN
                  </a>
                </li>
              </ul>
            )}
          </div>

          {
            <button
              onClick={() => setsidebar(!oppentsidebar)}
              className={`responsive-btn`}
            >
              {!oppentsidebar ? (
                <Icon
                  icon="ion:reorder-three-outline"
                  width="30"
                  height="100%"
                />
              ) : (
                <Icon icon="maki:cross" />
              )}
            </button>
          }
        </div>
        {isLoginOpen && (
          <div id="loginForm" className="login-dropdown">
            <Link to="/optical">
              {" "}
              <i
                i
                onClick={closeLoginForm}
                className=" cross fa-solid fa-xmark"
              ></i>
            </Link>
            <form className="login-form">
              <h2>Login Form</h2>
              <br />
              <hr />
              <div>
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Register Mobile Number/Email"
                />
                <hr />
              </div>
              <br />
              <button className="submit" type="submit">
                Next
              </button>

              <p class>OR</p>

              <Link to="/account">
                {" "}
                <button className="  bg-blue-700 submit1" type="submit">
                  Create Account
                </button>{" "}
              </Link>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default Opticalnav;
