import React from "react";
import "./css/optcard.css";
import Ophtha from "../Component/image/website/Ophtha.png"
import clinic from "../Component/image/website/clinic.png"
import pharmacy from "../Component/image/website/pharmacy.webp"

function Optcard() {
  const cardData = [

    {
      imgSrc:
        Ophtha,
      heading: "EMR for Ophthalmology",
      description:[
        "Our software for eye doctors is designed to fit how  doctor likes to work.",
        "the software version is simpler and easier to understand for someone who might not be familiar with our software.",
      ]
    },
    {
      imgSrc:
       clinic,
      heading: "Eye hospital/clinic Management insights",
      description:
        "Show how you are better than others. Our eye clinic.hospital software helps you understand how things work better, with easy-to-get history&reports. This makes things faster and smoother for patients, and keeps them safe.",
    },
    {
      imgSrc:
      pharmacy,
      heading: "Optical & pharmacy inventory",
      description:
        "Enables tracking of all the inventory stock details in the optical store & operation theatre with great efficiency.",
    },

    {
      imgSrc:
        "https://myopticalapp.com/loginpage/common_assets/images/business_app/multi%20location%20accessibility.png",
      heading: "GST Invoicing/Billing",
      description:
        "YES-BM GST billing software helps businesses create professional invoices in just a few clicks",
    },
    {
      imgSrc:
        "https://myopticalapp.com/loginpage/common_assets/images/business_app/simplified%20billing2.png",
      heading: "Manage Your Inventory",
      description:
        "Not just invoicing, the billing software is also capable of managing the business inventory.",
    },
    {
      imgSrc:
        "https://myopticalapp.com/loginpage/common_assets/images/business_app/easily%20manage%20inventory2.png",
      heading: "Accounting Made Easy",
      description:
        "YES-BM software helps small business owners manage their finances more efficiently.",
    },
    {
      imgSrc:
        "https://myopticalapp.com/loginpage/common_assets/images/business_app/sale%20&%20secure%20inventory2.png",
      heading: "Collect Payments Faster",
      description:
        "The billing software not just generates invoices in seconds but also allows sharing them online in no time.",
    },
    {
      imgSrc:
        "https://myopticalapp.com/loginpage/common_assets/images/business_app/Work%20Online_Offline3.jpg",
      heading: "Analyse business reports",
      description:
        "YES-BM billing and accounting software converts all your business data into information that can be used to make strategic business decisions.",
    },
    {
      imgSrc:
        "https://myopticalapp.com/loginpage/common_assets/images/business_app/Multiple%20Payment%20Options3.png",
      heading: "Manage Accounts Receivable/Payable",
      description:
        "With the help of YES-BM billing software, business owners can manage & keep track of their complete finances.",
    },
  ];

  return (
    <>
      <div>
        <div className="Optcard">
          {cardData.map((card, index) => {
            return (
              <div className="card hover:scale-105 duration-200">
                <img src={card.imgSrc} alt="" />
                <h5>{card.heading}</h5>
                <p>{card.description}</p>
              </div>
            );
          })}

          {/* <div className="card hover:scale-105 duration-200">
            <img
              src="https://myopticalapp.com/loginpage/common_assets/images/business_app/multi%20location%20accessibility.png"
              alt=""
            />
            <h5>GST Invoicing/Billing</h5>
            <p>
              YES-BM GST billing software helps businesses create professional
              invoices in just a few clicks
            </p>
          </div>

          <div className="card hover:scale-105 duration-200">
            <img
              src="https://myopticalapp.com/loginpage/common_assets/images/business_app/simplified%20billing2.png"
              alt=""
            />
            <h5>Manage Your Inventory</h5>
            <p>
              Not just invoicing, the billing software is also capable of
              managing the business inventory.
            </p>
          </div>

          <div className="card hover:scale-105 duration-200">
            <img
              src="https://myopticalapp.com/loginpage/common_assets/images/business_app/easily%20manage%20inventory2.png"
              alt=""
            />
            <h5>Accounting Made Easy</h5>
            <p>
              YES-BM software helps small business owners manage their finances
              more efficiently.
            </p>
          </div>

          <div className="card hover:scale-105 duration-200">
            <img
              src="https://myopticalapp.com/loginpage/common_assets/images/business_app/sale%20&%20secure%20inventory2.png"
              alt=""
            />
            <h5>Collect Payments Faster</h5>
            <p>
              The billing software not just generates invoices in seconds but
              also allows sharing them online in no time.
            </p>
          </div>

          <div className="card hover:scale-105 duration-200">
            <img
              src="https://myopticalapp.com/loginpage/common_assets/images/business_app/Work%20Online_Offline3.jpg"
              alt=""
            />
            <h5>Analyse business reports</h5>
            <p>
              YES-BM billing and accounting software converts all your business
              data into information that can be used to make strategic business
              decisions.
            </p>
          </div>

          <div className="card hover:scale-105 duration-200">
            <img
              src="https://myopticalapp.com/loginpage/common_assets/images/business_app/Multiple%20Payment%20Options3.png"
              alt=""
            />
            <h5>Manage Accounts Receivable/Payable</h5>
            <p>
              With the help of YES-BM billing software, business owners can
              manage & keep track of their complete finances.
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Optcard;
