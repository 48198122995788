import React from "react";
import "./Highlited.css";
import { Icon } from "@iconify/react/dist/iconify.js";

const Highlited = () => {
  const data = [
    {
      icon: <Icon icon="cil:smile" />,
      maintext: "10 Thousand+",
      Subtext: "Happy users",
    },
    {
      icon: <Icon icon="mingcute:rewind-forward-15-line" />,
      maintext: "15+",
      Subtext: "Years of Experience",
    },
    {
      icon: <Icon icon="ion:people-sharp" />,
      maintext: "50+",
      Subtext: "Country Users Across The World",
    },
    {
      icon: <Icon icon="rivet-icons:device" />,
      maintext: "Multi Devices",
      Subtext: "Use Together on Mobile/Desktop",
    },
    {
      icon: <Icon icon="material-symbols:star-rounded" />,
      maintext: "Rated 4.5/5",
      Subtext: "On Google & Google Play Store",
    },
  ];
  return (
    <>
      <div className={`Highlited`}>
        <h2>
          Future of <span>business is more complex</span>, You need an easy and
          simple software
        </h2>

        <div className={`icons`}>
          {data.map((item, index) => {
            return (
              <>
                <div className={`single-div`}>
                  <i>{item.icon}</i>
                  <h3>{item.maintext}</h3>
                  <p>{item.Subtext}</p>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Highlited;
