import React, { useEffect } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import "./dropdownCSS/Brand.css"
import About from "../About"
import Footer1 from "../Footer1"
import { Helmet } from 'react-helmet'
function Brand() {
 
  useEffect(() => {
    document.title = 'Web Design and Development : Techcherry';
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', 'Techcherry Software Consultancy Services Pvt. Ltd. is famous Web Designing Company in India provide best web design services. Website design is a procedure to combine lots of ideas, colors, images...');
  }, []);

  return (
    
    <div>
       <Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>Web Design and Development : Techcherry</title>
        <meta name="description" content="Techcherry Software Consultancy Services Pvt. Ltd. is famous Web Designing Company in India provide best web design services. Website design is a procedure to combine lots of ideas, colors, images...." />
        <meta name="keywords" content=" web design and development, web design and development company, web design development, web design & development, 
            web design and development services,web design development company,web designing and development company,web design & development company 
            web development and design,web design and development companies,web development design,web design web development, 
            web designing and development companies,web design development companies,web designing & development company,web design and development websites, 
            web design and web development company,best web design and development company,web design and web development,web development and design company 
            web designing and development,web designers and developers,web development and design companies,web development and designing company,web design developers,web design developer,web designing and web development,web design web development company." />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="Web Design and Development : Techcherry" />
        <meta property="og:description" content="Techcherry Software Consultancy Services Pvt. Ltd. is famous Web Designing Company in India provide best web design services. Website design is a procedure to combine lots of ideas, colors, images...." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Footer/>
      <Navbar/>

    <div className='Brand'>
        <h1>Web Site Design</h1>
        <div className='brand-line'>
        <div className='brand-Card'>
      <div className="brand-card bg-orange-500">
        <h4>Beutiful</h4>
        <i class="fa-solid fa-laptop"></i>
        <p>Beautiful websites give better user experience with interactive UI that have better user experience, lesser bounce rates, stand out to give you an edge over competitors & have that wow factor can compel your visitors to convert. Our creative designing team spends time to understand your business objectives & target niche before starting the designing process.</p>
      </div>
      <div className="brand-card bg-red-600">
        <h4>Responsive</h4>
        <i class="fa-solid fa-mobile-screen-button"></i>
        <p>User friendly websites for multi device world More than 70% of your website visitors are now using mobile. We develop fully responsive websites with intelligent user interface that adapts to varied screen resolutions and deliver an amazing user experience regardless of the device and browsing environment.</p>
      </div>
      <div className="brand-card bg-blue-900">
        <h4>Effective</h4>
        <i class="fa-solid fa-spinner "></i>
        <p>Beautiful websites give better user experience Unique web designs with interactive UI that have better user experience, lesser bounce rates, stand out to give you an edge over competitors & have that wow factor can compel your visitors to convert. Our creative designing team spends time to understand your business objectives & target niche before starting the designing process.</p>
      </div>
      <div className="brand-card  bg-green-600">
        <h4>Customized</h4>
        <i class="fa-solid fa-display"></i>
        <p>Beautiful websites give better user experience Unique web designs with interactive UI that have better user experience, lesser bounce rates, stand out to give you an edge over competitors & have that wow factor can compel your visitors to convert. Our creative designing team spends time to understand your business objectives & target niche before starting the designing process.</p>
      </div>
      </div>
   </div>
   </div>
   <About/>
  <Footer1/>
    </div>
  )
}

export default Brand
