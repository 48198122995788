import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import "./dropdownCSS/opticommerce.css"
import back from "./image/opticommerce.png"
import custom from "./image/service-card.jpg"
import About from '../About'
import Footer1 from '../Footer1'

function Constom() {
   document.title=" Techcherry- Opticommerce "
  return (
    <div>
      <Footer/>
      <Navbar/>
      <div>
        <div className="opticommerce">
            <div className='opticommerce1'>
                <div className="opticommerce-box">
               <h1>Mobile Commerce App Development</h1>
                <p>Worldwide consumer spending via mobile apps is witnessing dramatic growth. Is your business prepared to tap into this growth story? Tech cherry has an excellent track record of bringing the best of mobile commerce solutions to clients. Our core focus is on creating an effective mobile presence for businesses that boosts customer engagement and triggers sales. Our designers and developers have comprehensive experience in using open source and proprietary platforms to create immersive and profitable mobile commerce experiences that deliver high ROI. Moveo makes sure the design and functionality of the mobile commerce solution is purely driven by the needs of the customers and they are able to shop for products quickly and conveniently. This helps improve mobile commerce conversions.</p>
                </div>
            </div>
            <div className='opticommerce2'>
                <img src={back} alt="" />
            </div>
        </div>

        <div className='opticommerce-container'>
            <h1 className='text-blue-800 mt-6 text-[26px]'>Responsive Web App Development process we follow</h1>
        <img src={custom} alt="" />
        </div>
      </div>
      <About/>
      <Footer1/>
    </div>
  )
}

export default Constom
