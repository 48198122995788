import React, { useState } from 'react'
import "./ResCss/resbiiling.css"
import restorant from "../Component/image/website/Techcherry-Restaurent.webp"
import { Icon } from "@iconify/react/dist/iconify.js";

function Resbilling() {

  const [formData, setFormData] = useState({

    phone: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic, such as sending data to backend
    console.log(formData);
  };
  const handleWhatsapp = () => {
    const phone = formData.phone.trim(); //Ensure phone number is cleaned up
    if (phone) {
      window.location.replace(`whatsapp://send/?phone=9039024004&text=Hello Rajesh i want to take the demo on your restaurant`)
    }
    else {
      alert("plese inter valid phone number")
    }
  }

 

  const handleCall = () => {
    const phoneNumber = "9039024004";

    const callUrl = `tel:${phoneNumber}`;

    window.location.href = callUrl;
  };

  return (
    <div className='Resbilling'>
      <div className='resbilling'>
        <img className={`!w-full`} src={restorant} alt="" />
        <div className='resbill-box'>
          <form className=' resinput' onSubmit={handleSubmit}>
            <input className="input-mobile-number" type='number' name="phone" placeholder='Enter mobile number' onChange={handleChange}
              value={formData.phone} />
            <hr />
            <div className="flex-trial-free">
              <button className='resget'
                onClick={handleWhatsapp}>GET FREE TRIAL</button>
            </div>
          </form>
          <div className="numbers-optical-call">
            <div className="flex-number-whatsapp" onClick={() => handleCall()}>
              <div className="phon-icon-no"><Icon icon="fluent:call-20-filled" /></div>
              <div className="Number-call-marginleft">+91-9039024004</div>
            </div>

            <div className="flex-number-whatsapp" onClick={() => handleWhatsapp()}>
              <div className="whatsapp-icon-no"><Icon icon="logos:whatsapp-icon" /></div>
              <div className="Number-call-marginleft">+91-9039024004</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Resbilling
