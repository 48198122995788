import React, { useState } from "react";
import "./Frequintly.css";
import { Icon } from "@iconify/react/dist/iconify.js";

const Frequition = () => {
  const [arrayforopen, setarrayforopen] = useState([]);
  const quetions = [
    {
      quetion: "What is Myopticalapp ?",
      answer:
      "myopticalapp is an optical’s billing and stock management application. It can be used to manage stock, customer details, patient prescription details, lens prescription, and invoicing. It is also available for desktop and web-based applications.\n\n" +
      "\nComplete solution for:\n" + // Start on a new line
      "\n- Single/multiple/Franchise optical retail store\n" +
      "\n- Eye clinic/Eye Hospitals\n" +
      "\n- Lens/Frame/contact lens wholesale/Distributors\n\n" +
      "For more information, WhatsApp now: 9039024004",
    },

    {
      quetion: "What is Ophthalmology software?",
      answer:[
        "Streamline your eye care practice with Techcherry, the comprehensive ophthalmology clinic/Hospital software. Manage patient appointments, electronic health records, optical inventory, and billing with ease. Improve patient care, enhance efficiency, and grow your practice with our user-friendly and feature-rich solution.",
        "Integrated Ophthalmology Practice with Optical & Pharmacy"
      ]
      },
    {
      quetion: "What is YES-BM?",
      answer:
        "Grow your retail/wholesale business with YES-BM, the scalable and cloud-based/offline inventory management solution. Access your data anytime, anywhere, and collaborate with your team seamlessly. Our software adapts to your business needs as you grow, ensuring you always have the tools you need to succeed. Boost Your Retail/wholesale business Sales with YES-BM - Apparel | Footwear |Hardware |Grocery | General stores",
    },
    {
      quetion: "What is mymedicalcrm?",
      answer:[
        "Say goodbye to stockouts and expired medications. mymedicalcrm provides real-time inventory tracking, automated stock alerts, and powerful reporting to help you optimize stock levels, minimize wastage, and maximize profitability. Improve your pharmacy's efficiency and customer satisfaction with our intelligent inventory management solution.",
        "mymedicalcrm.in - Increase Your Pharmacy's Profits with Smart Inventory Management",
      ]
      },
    {
      quetion: "what is myrestaurantcrm?",
      answer:[
        "Streamline your restaurant operations with myrestaurantcrm.com, the powerful and user-friendly restaurant management software. From online ordering and table reservations to inventory management and employee scheduling, our solution helps you increase efficiency, improve customer satisfaction, and boost your bottom line.",
        "Boost Your Restaurant with myrestaurantcrm.com - The All-in-One Restaurant Management Solution",
      ]
      },
    {
      quetion: "What is YES-IM?",
      answer: [
        "Simplify school administration with myschoolcampus, the leading school ERP software. Our comprehensive solution offers a range of features including student information management, online admissions, fee management, attendance tracking, teacher and parent portals, and robust communication tools. Streamline operations, improve efficiency, and enhance the overall learning experience with myschoolcampus.",
        "Streamline Your School with myschoolcampus - The User-Friendly School ERP",
        "Complete software solution for School/College/Institute.",
      ]
    },
    {
      quetion: "Is my data safe with Techcharry POS?",
      answer:
        "Absolutely, and it’s a firm commitment from us! We value transparency and strong work ethics. Your entire data is in good hands and kept safe and secure in the Ciferon clouds.",
    },
    {
      quetion: "What about support?",
      answer:
        "Count on it! Techcherry support is like your 24/7 superhero, always there for you. Day or night, even on holidays and during festivals, our support team is ready to help you and your team. It’s a promise we’re proud to keep!",
    },
  ];

  return (
    <>
      <div className={`frequently-div`}>
        <h2>Frequently Asked Questions</h2>

        <div className={`div-for-quetion`}>
          {/* -------left--------- */}
          <ul>
            {quetions.slice(0, 4).map((item, index) => {
              return (
                <>
                  <li className="li-for-quition">
                    <p
                      className={`qution-p`}
                      onClick={() =>
                        setarrayforopen((prev) =>
                          arrayforopen.includes(index)
                            ? arrayforopen.filter((item) => item !== index)
                            : [...prev, index]
                        )
                      }
                    >
                      {item.quetion}
                      <Icon icon="nrk:arrow-dropdown" width="20" height="20" />
                    </p>
                    <div
                      style={{
                        display: arrayforopen.includes(index)
                          ? "block"
                          : "none",
                      }}
                    >
                      <p>
                        <span style={{ color: "red", padding: "0px" }}>
                          {"Ans: "}
                        </span>
                        {item.answer}
                      </p>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>

          {/* ------frequition------- */}
          <ul>
            {quetions.slice(4, 8).map((item, index) => {
              return (
                <>
                  <li className="li-for-quition">
                    <p
                      className={`qution-p`}
                      onClick={() =>
                        setarrayforopen((prev) =>
                          arrayforopen.includes(index + 4)
                            ? arrayforopen.filter((item) => item !== index + 4)
                            : [...prev, index + 4]
                        )
                      }
                    >
                      {item.quetion}
                      <Icon icon="nrk:arrow-dropdown" width="20" height="20" />
                    </p>
                    <div
                      style={{
                        display: arrayforopen.includes(index + 4)
                          ? "block"
                          : "none",
                      }}
                    >
                      <p>
                        <span style={{ color: "red", padding: "0px" }}>
                          {"Ans: "}
                        </span>
                        {item.answer}
                      </p>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Frequition;
