import React from "react";
import Optbiling from "../Optbiling";
import Opticalnav from "../Opticalnav";

import Feature from "../Optfeature";
import Customer from "../Optcustomer";
import Optcard from "../Optcard";
import Optform from "../Optform";
import Optform1 from "../Optform1";
import Optvideo from "../Optvideo";
import Optabout from "../Optabout";
import { Helmet } from "react-helmet";
import "../css/Responsive.css";

function Opticalhome() {
  return (
    <div>
      <Helmet>
        {" "}
        {/* used for make a sco friendly */}
        <title>
          Optical Shop Software | Optical Store Billing software | YES Optical
          Management Software : Techcherry
        </title>
        <meta
          name="description"
          content="YES-OPTICAL is a complete Point of Sale (POS) Solution for Optical shop. Our core focus is “Our Client’s” and we use that to guide our day-to-day business decisions. Try us first before......"
        />
        <meta
          name="keywords"
          content="Optical Store Software, Optical Shop Software, optical billing software india,optical software,optical retail software,optical shop software,software for optical shop,optical software for retail stores,optical store software,optic software,optical inventory software,software for optical shops,optical software solutions,optical software programs,optical design software open source,optics software,optical software free,free optical software,optical shop software free download,optical software free download,optical billing software,software for optical shop free download,optical store software free download."
        />
        <link rel="canonical" href="https://yourwebsite.com/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content="Optical Shop Software | Optical Store Billing software | YES Optical Management Software : Techcherry"
        />
        <meta
          property="og:description"
          content="YES-OPTICAL is a complete Point of Sale (POS) Solution for Optical shop. Our core focus is “Our Client’s” and we use that to guide our day-to-day business decisions. Try us first before......"
        />
        <meta
          property="og:image"
          content="https://yourwebsite.com/thumbnail.jpg"
        />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Opticalnav NavbarType={"OPTICAL"} />
      <Optbiling />
      <Feature />
      <Customer />
      <Optcard />
      <Optform />
      <Optform1 />
      <Optvideo />
      <Optabout />
    </div>
  );
}

export default Opticalhome;
