import React, { useEffect, useState } from 'react'

import "./CSS/Product.css"
import Backend_Url from '../Config/BackendUrl';
import token from '../Config/Token';
import axios from 'axios'
import { Icon } from '@iconify/react/dist/iconify.js';

function Product() {
  const [allbodyCard, setallBodyCard] = useState([]);

  console.log(allbodyCard);

  //   const fetchcard = async () => {
  //     await fetch(`${Backend_Url}HPBodyCard/getall`, {
  //         headers: {
  //             'authorization': 'Bearer ' + token
  //         }
  //     })
  //         .then((res) => res.json())
  //         .then((data) => setallBodyCard(data.data))
  //         .catch((err) => window.alert("Can't fetch all body", err))
  // }

  const fetchcard = async () => {
    try {
      const response = await axios.get(`${Backend_Url}/HPBodyCard/getall`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Access the data directly from the response
      const data = response.data;

      if (data && data.data) {
        setallBodyCard(data.data); // Update your state with the fetched data
      } else {
        throw new Error('No data found in response');
      }
    } catch (err) {
      // Handle errors more gracefully
      console.error('Error fetching data:', err);
      window.alert(`Error: ${err.message}`);
    }
  };

  useEffect(() => {
    fetchcard();
  }, []);

  const handleWhatsapp = () => {
    window.location.href = `whatsapp://send/?phone=9039024004&text=Hello Rajesh, I want to Know about your Products.`;
  };

  return (
    <>
      <h3 className='text-4xl text-center justify-center mt-5 text-blue-800 rated-product-name'>Our Top Rated Product</h3>
      <div className='Product-box'>

        {allbodyCard.filter((data) => data.Title === 'Product').map((product, index) => (
          <div className='productbox bg-blue-800  hover:scale-105 duration-200 product-service-clr'>
            {/* <i className="fas fa-mobile-alt"></i> */}
            <div className='product-rated-img'>
              <img src={product.CardImage} alt="" />
            </div>
            <h5 className='text-center text-white product-heading-name'>{product.Heading}</h5>
            {/* <p className=' p1'>(www.myopticalapp.com)</p>
          <p>( No.1 Optical Stock & Billing
            Software In India )</p> */}
            <p className='sub-details-name'>{product.Details}</p>
            <div className='Product-sub1'>
              <div className='product-sub1'>
                <button onClick={handleWhatsapp}>Enquiry Now</button>
              </div>
              <div className='product-sub2'>
                <a href={`tel:${product.PhoneNumber}`} className="fa-solid fa-square-phone"></a>
                <a>
                  <Icon icon="logos:whatsapp-icon" width="30" height="30" />
                </a>
              </div>
            </div>
          </div>

        ))}



        {/* <div className='productbox  bg-orange-400 hover:scale-105 duration-200'>
        <i class="fa-regular fa-pen-to-square"></i>
          <h5 className='text-center'>YES-BM</h5>
          <p>( Best Stock & Billing
            Software) </p>
          <div className='Product-sub2'>
              <div className='product-sub1'>
                <p>enquiry now</p>
              </div>
              <div className='product-sub2'>
              <i class="fa-solid fa-square-phone"></i>
              </div>
            </div>
        </div> */}

        {/* <div className='productbox   bg-blue-500 hover:scale-105 duration-200'>
          <i className="fas fa-eye"></i>
          <h5 className='text-center'>YES-IM</h5>
          <p>( One Of The Best
            Institute ERP )</p>
          <div className='Product-sub3'>
              <div className='product-sub1'>
                <p>enquiry now</p>
              </div>
              <div className='product-sub2'>
              <i class="fa-solid fa-square-phone"></i>
              </div>
            </div>
        </div>

        <div className='productbox  bg-orange-400 hover:scale-105 duration-200'>
          <i className="fas fa-mobile"></i>
          <h5  className='text-center'>CRM</h5>
          <p>( Product and
            Service Based )</p>
          <div className='Product-sub4'>
              <div className='product-sub1'>
                <p>enquiry now</p>
              </div>
              <div className='product-sub2'>
              <i class="fa-solid fa-square-phone"></i>
              </div>
            </div>
        </div> */}
      </div>

    </>
  )
}

export default Product