import React, { useState } from 'react'
import "./ResCss/resform.css"

function Resform() {
    const [openSection, setOpenSection] = useState(null);

    // Function to toggle dropdown sections
    const toggleDropdown = (section) => {
      setOpenSection(openSection === section ? null : section);
    };
  
    return (
      <>
        <div className='Resform'>
          <h3 className='text-center text-4xl mt-5' id='retail'>
            <span className='text-amber-700'> Who </span>is it For
          </h3>
  
          <div className='sub-resform'>
            {/* Section 1 */}
            <div className='resform1'>
              <p
                className=' cursor-pointer'
                onClick={() => toggleDropdown('retailers')}
              >
                <span className='bg-red-200'>+</span>How many POS will I need to buy to use it in multiple terminals?

              </p>
              {openSection === 'retailers' && (
                <div className='resdropdown-content'>
                  <li> Techcherry POS runs smoothly with multiple billing stations! Whether it is two billing stations of 10, you need just one POS to manage them all without hassle.</li>
                 
                </div>
              )}
            </div>
  
            {/* Section 2 */}
            <div className='resform1'>
              <p
                className=' cursor-pointer duration-200'
                onClick={() => toggleDropdown('wholesalers')}
              >
                <span className='bg-red-200'>+</span>Which hardware will I be required to use Techcherry POS?
              </p>
              {openSection === 'wholesalers' && (
                <div className='resdropdown-content'>
                  <li>Techcherry POS works smoothly with iOS, Windows, and Android laptops, computers, tablets and phones.</li>
               
                </div>
              )}
            </div>
  
            {/* Section 3 */}
            <div className='resform1'>
              <p
                className='cursor-pointer'
                onClick={() => toggleDropdown('eyeclinic')}
              >
                <span className='bg-red-200'>+</span>Does Techcherry POS support an A4 printer?
              </p>
              {openSection === 'eyeclinic' && (
                <div className='resdropdown-content'>
                  <li>Yes! Techcherry POS supports your regular A4 printers and WiFi, LAN, USB, Dot-matrix, Laser, Label, A4 size, A5 size, Bluetooth, etc.!
                  </li> 
                </div>
              )}
            </div>
  
            {/* Section 4 */}
            <div className='resform1'>
              <p
                className=' cursor-pointer'
                onClick={() => toggleDropdown('ecommerce')}
              >
                <span className='bg-red-200'>+</span>What if I’m switching from another POS?
              </p>
              {openSection === 'ecommerce' && (
                <div className='resdropdown-content'>
                 You have nothing to worry about! We will make sure your POS migration is quick so that your operations don’t get affected.
                  <li>The team will help migrate all the CRM data from your previous POS to Techcherry.</li>
                  <li>The team will also help re-install the previous aggregator setup onto Techcherry POS</li>
               
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
}

export default Resform
