import React from "react";
import "./BackImageAttachment.css";
import backimg from "../image/paymo-VG3ji6cIYk8-unsplash.jpg";

const BackImageAttachment = () => {
  return (
    <>
      <div
        className={`backattachment`}
        style={{ backgroundImage: `url(${backimg})` }}
      >
        <div className={`text-div`}>
          {/* <p>- Experience Accounting & Stock Management on the Go</p> */}
          <h2>Effortless Cloud based solution for your business</h2>
        </div>
      </div>
    </>
  );
};

export default BackImageAttachment;
