import React from 'react'
import "./ResCss/rescard.css"

function Resrescard() {
    return (
        <>
        <div>
            <div className="Rescard">
    
                <div className="rescard hover:scale-105 duration-200">
                   <i  class="fa-solid fa-receipt 'text-red-700 "></i>
                    <h5>Billing</h5>
                    <p>YES-BM GST billing software helps businesses create professional invoices in just a few clicks</p>
                </div>
    
                <div className="rescard hover:scale-105 duration-200">
                <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                    <h5> Inventory</h5>
                    <p>Not just invoicing, the billing software is also capable of managing the business inventory.</p>
                </div>
    
                <div className="rescard hover:scale-105 duration-200">
                <i class="fa-solid fa-cart-shopping"></i>
                    <h5>Online Order</h5>
                    <p>YES-BM software helps small business owners manage their finances more efficiently.</p>
                </div>
                
                <div className="rescard hover:scale-105 duration-200">
                <i class="fa fa-file" aria-hidden="true"></i>
                    <h5>Report</h5>
                    <p>The billing software not just generates invoices in seconds but also allows sharing them online in no time.</p>
                </div>
    
                <div className="rescard hover:scale-105 duration-200">
                <i class="fa-solid fa-list"></i>
                    <h5>Menu</h5>
                    <p>YES-BM billing and accounting software converts all your business data into information that can be used to make strategic business decisions.</p>
                </div>
            
                <div className="rescard hover:scale-105 duration-200">
                <i class="fa-regular fa-handshake"></i>
                    <h5>CRM</h5>
                    <p>With the help of YES-BM billing software, business owners can manage & keep track of their complete finances.</p>
                </div>
            </div>
        </div>
        </>
      )
}

export default Resrescard
