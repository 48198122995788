import React, { useEffect, useState } from 'react'
import "./CSS/about.css"
import axios from 'axios'
import Backend_Url from '../Config/BackendUrl'
import token from '../Config/Token'
import { Helmet } from 'react-helmet'
import { Icon } from "@iconify/react/dist/iconify.js";

function About() {

   const [allcontent, setallcontent] = useState([])
   console.log(allcontent)
  

    const fetchdata = async () => {
        try {
            await axios.get(`${Backend_Url}/Footer/getAll`,
                {
                    headers: {
                        'authorization': 'Bearer ' + token
                    }
                }
            )
                .then((res) => setallcontent(res.data.data))
        } catch (error) {
            console.log(error)
            return window.alert('Error while fetching data')
        }
    }
    useEffect(() => {
         fetchdata();
     }, [])

  return (
    <>
     <Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>TechCherry | Application Designing, Development & Services</title>
        <meta name="description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta name="keywords" content=" Products, Services, Tech Solutions, Custom Software , Restaurant, Optical Software" />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="Techcherry - My React Website" />
        <meta property="og:description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
     <div className='about bg-blue-900 footer-top-we'>
        <div className='about1'>
        <b><h4>Address</h4> </b>
        <hr />
          <hr />
          <ul>
              <li className='flex-address-front'>
                <div className='address-home-icon'> <Icon icon="line-md:home-twotone" width="24" height="24" /></div>
                <div className='address-main'>Durg Branch - Ground Floor, Armaan Palace, Maharaja Chowk, Adarsh Nagar, Durg (C.G) 491001</div>
                </li>
                <li className='flex-address-front'>
                <div className='address-home-icon'><Icon icon="fluent:call-24-filled" width="24" height="24" /></div>
                <div className='address-main'>Contact No - 07884040055, +91 8871439741, +91 9039024004</div>
                </li>
              <li className='flex-address-front'>
              <div className='address-home-icon'> <Icon icon="line-md:home-twotone" width="24" height="24" /></div>
                <div className='address-main'>Raipur Branch - Sector-9, Kamal Vihar, Opp to MMR hospital, V.Y Hospital Chowk, Raipur (C.G) 492004</div>
                </li>
              <li className='flex-address-front'>
                <div className='address-home-icon'><Icon icon="fluent:call-24-filled" width="24" height="24" /></div>
                <div className='address-main'>Contact No - 9039024002</div>
                </li>
              <li className='flex-address-front'>
                <div className='address-home-icon'><Icon icon="ic:twotone-email" width="24" height="24" /></div>
                <div className='address-main'>Email Id - contact@techcherry.in</div>
                </li>
          </ul>
        </div>
        {allcontent && allcontent.map((about,index)=>(
           <div  className='about1'>
           <b><h4>{about.Title}</h4> </b>
           {/* <div className='border-bottom-line'></div> */}
          <hr />
          <hr />
          <ul>
              <li  dangerouslySetInnerHTML={{ __html: about.TextContent }}/>   
          </ul>
     </div> 
       
         
        ))}
         
        {/* <div  className='about1'>
          <b><h4>About</h4> </b>
         <hr />
         <hr />
         <ul>
            <li>  who we are</li>
            <li> what we do</li>
            <li> Vision & Mission</li>
            <li> Testimonials</li>
         </ul>
        </div> */}
        {/* <div className='about2'>
        <b><h4>SERVICES</h4></b>
         <hr />
         <hr />
         <ul>
            <li>  Customized Mobile & Web App</li>
            <li> Development</li>
            <li> Opti-Commerce Development</li>
            <li> Brand Creation</li>
            <li>Ecommerce</li>
         </ul>
        </div> */}
        {/* <div className='about3'>
         <b><h4>PRODUCT</h4></b>
         <hr />
         <hr />
         <ul>
            <li> My Optical App</li>
            <li> YES BM</li>
            <li> YES IM</li>
            <li> CRM</li>
         </ul>
        </div> */}
        {/* <div className='about4'>
        <iframe width="260" height="205" src="https://www.youtube.com/embed/BrTogW4BI-0?si=ZiK5xc6RxfxGWIme" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div> */}
     </div>
    </>
  )
}

export default About

// import React, { useState, useEffect } from "react";
// import "./CSS/about.css";
// import axios from "axios";
// import Backend_Url from "../Config/BackendUrl";
// import token from "../Config/Token";

// function About() {
//   const [allcontent, setallcontent] = useState([]);
//   console.log(allcontent);
//   // Fetch data from backend
//   const fetchdata = async () => {
//     debugger;
//     try {
//       const response = await axios.get(`${Backend_Url}Footer/getAll`, {
//         headers: {
//           authorization: "Bearer " + token,
//         },
//       });
//       const result = await response.data.data;
//       setallcontent(result); // Assuming response.data.data is the array
//     } catch (error) {
//       console.log(error);
//       window.alert("Error while fetching data");
//     }
//   };

//   // Fetch data on component mount
//   useEffect(() => {
//     fetchdata();
//   }, []);

//   return (
//     <>
//       <div className="about bg-blue-700">
//         {Array.isArray(allcontent) && allcontent.length > 0 ? ( // Check if allcontent is an array and has data
//           allcontent.map((item, index) => (
//             <div key={index} className={`about${index + 1}`}>
//               <b>
//                 <h4>{item.Title}</h4>
//               </b>
//               <hr />
//               <hr />
//               <ul>
                
//               <div className="leading-8 text-lg text-left md:text-left" dangerouslySetInnerHTML={{__html:allcontent[2]?.TextContent}}/>
              
//               </ul>
//             </div>
//           ))
//         ) : (
//           <p>Loading...</p> // Show loading message while data is being fetched
//         )}
//         <div className='about4'>
//           <iframe
//             width="360"
//             height="215"
//             src="https://www.youtube.com/embed/BrTogW4BI-0?si=ZiK5xc6RxfxGWIme"
//             title="YouTube video player"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             referrerPolicy="strict-origin-when-cross-origin"
//             allowFullScreen
//           />
//         </div> 
//       </div>
//     </>
//   );
// }

// export default About;
