import React from 'react'
import "./ResCss/resfooter.css"
function Resfooter() {
  return (
    <>
      <div className='resfooter'>
        <footer>
          <p>Techcherry Restaurant</p>
          <div className='resfooter1'>
            <a href="https://www.linkedin.com/in/techcherry/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
            <a href="https://www.facebook.com/techcherry.software/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/techcherry_official/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a href="https://www.youtube.com/@techcherryinfosystem2223" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-youtube"></i>
            </a>
          </div>

          <div className='resfooter2'>
            <i class="fa-solid fa-phone"></i>
            <li>9309024004</li>
          </div>

          <div className='resfooter3'>
            <i className="fa-regular fa-envelope"></i>
            <i>contact@techcherry.in</i>
          </div>

        </footer>
      </div>
    </>
  )
}

export default Resfooter
