// import React from 'react'
// import "./css/optform.css"
// function Optform() {
//   return (
//     <>
//     <div className='Optform'>
//         <h3 className='text-center text-4xl'> <span className='text-amber-700 '> Who </span>is it For</h3>

//         <div className=' sub-optform'>
//             <div className='optform1'>
//             <p  className= ' bg-slate-300' > <span className='bg-slate-400'>+</span>For Retailers/ Chain Stores - Faster Billing and POS   +   </p>
//             </div>

//             <div className='optform1'>
//             <p className='bg-slate-300'> <span className='bg-slate-400'>+</span>For Lens Wholesalers </p>
//             </div>

//             <div className='optform1'>
//             <p  className='bg-slate-300'> <span className='bg-slate-400'>+</span>For EYECLINIC        </p>
//             </div>

//             <div className='optform1'>
//             <p  className='bg-slate-300'>  <span className='bg-slate-400'>+</span>For ecommerce/ opti-commerce </p>
//             </div>
//         </div>
//     </div>
//     </>
//   )
// }

// export default Optform

import React, { useState } from 'react';
import "./css/optform.css";

function Optform() {
  // State to control dropdowns
  const [openSection, setOpenSection] = useState(null);

  // Function to toggle dropdown sections
  const toggleDropdown = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <>
      <div className='Optform'>
        <h3 className='text-center text-4xl' id='retail'>
          <span className='text-amber-700'> Who </span>is it For
        </h3>

        <div className='sub-optform'>
          {/* Section 1 */}
          <div className='optform1'>
            <p
              className='bg-slate-300 cursor-pointer'
              onClick={() => toggleDropdown('retailers')}
            >
              <span className='bg-slate-400'>+</span>For Retailers/ Chain Stores - Faster Billing and POS
            </p>
            {openSection === 'retailers' && (
              <div className='dropdown-content'>
                <li> Make invoices with the counter system on Desktop/ android/ iOS app</li>
                <li>Digitise all day-to-day business transactions</li>
                <li>Track & manage business inventory accurately</li>
                <li>Take thermal printouts</li>
                 <img src="https://myopticalapp.com/loginpage/common_assets/images/business_app/YES-BM.JPG" alt="" />
              </div>
            )}
          </div>

          {/* Section 2 */}
          <div className='optform1'>
            <p
              className='bg-slate-300 cursor-pointer duration-200'
              onClick={() => toggleDropdown('wholesalers')}
            >
              <span className='bg-slate-400'>+</span>For Lens Wholesalers
            </p>
            {openSection === 'wholesalers' && (
              <div className='dropdown-content'>
                <li>Create lens power in one click</li>
                <li>Manage Rx order</li>
                <li>Take Rx/Ready order from app</li>
                <li>Create Customer category and prices as per category</li>
                <li>Set credit limit and credit days</li>
                <li>Manage customer outstandings& send payment reminders</li>
                <li>Share invoices via SMS and WhatsApp</li>
                <li>Manage inventory better with low stock alerts & categories</li>
                <img src="https://myopticalapp.com/loginpage/common_assets/images/business_app/LENS.JPG" alt="" />
              </div>
            )}
          </div>

          {/* Section 3 */}
          <div className='optform1'>
            <p
              className='bg-slate-300 cursor-pointer'
              onClick={() => toggleDropdown('eyeclinic')}
            >
              <span className='bg-slate-400'>+</span>For Eyeclinic/Eye Hospital
            </p>
            {openSection === 'eyeclinic' && (
              <div className='dropdown-content'>
                {/* <li>Create Patient Registration and generate PID</li>
                <li>Manage Patient Systemic Illness, Complaints, Risk Factor etc</li>
                <li>Manage optical inventory better with low stock alerts & categories</li>
                <li>Manage Biometry Details and schedule OT</li>
                <li>Generate multiple Certificates</li> */}
                <li>See all the information about a patient from every time they came to the clinic in one place.</li>
                <li>Easily see how a patient's eyesight has changed over time. You can see how their vision tests have gone and how their glasses prescriptions have been adjusted.</li>
                <li>Easily see a patient's past diagnoses, test results, and any orders placed, all in one place. You can also view clear and high-quality images related to their care.</li>
                <li>Keep track of the medicines they were given before, how often they took them, and how they took them</li>
                <li>Easily see all the past problems the patient reported, their overall health history, and the results of past eye checkups.</li>
                <li>Barcode/QR / SMS/ E-Mail / WhatsApp/ RFID integration</li>
                <img src="https://myopticalapp.com/loginpage/common_assets/images/business_app/EYECLINIC.JPG" alt="" />
              </div>
            )}
          </div>

          {/* Section 4 */}
          <div className='optform1'>
            <p
              className='bg-slate-300 cursor-pointer'
              onClick={() => toggleDropdown('ecommerce')}
            >
              <span className='bg-slate-400'>+</span>Who can use?
            </p>
            {openSection === 'ecommerce' && (
              <div className='dropdown-content'>
                {/* <li>Website Front end Theme with editable menu, banners, footers.</li>
                <li>Mobile commerce ready</li>
                <li>Your online store includes a built-in mobile commerce shopping cart. Your customers can browse and buy from your store using any mobile phone or tablet.</li>
                 <li>Your own domain name</li>
                 <li>Use your own domain name, or purchase from anywhere.</li>
                 <li>Payment gateways</li>
                 <li>Ready to use Default payment gateway.</li>
                 <li>Product organization</li>
                 <li>Organize products by category, type, season, sale, and more, through menu.</li>
                 <li>Product variations</li>
                 <li>Offer different variations of your products, such as multiple sizes, colors, materials, and more. Each variation can have its own price, SKU, weight, and inventory.</li>
                  <li>Multiple images</li>
                  <li>Add multiple images for your products, so you can show off your product from all angles.</li>
                  <li>SEO product tags</li>
                  <li>Optimize your product pages for search engines with product specific meta tags, titles, and URL handles.</li> */}
                  <li>Ophthalmologist</li>
                  <li>Optometrist</li>
                  <li>Opticians</li>
                  <li>Solo Practitioners</li>
                  {/* <img src="https://myopticalapp.com/loginpage/common_assets/images/business_app/Website.JPG" alt="" /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Optform;
