import React from "react";
import "./UsersReview.css";
import User1 from "../image/user1.png";

import Slider from "react-slick/lib/slider";

const UsersReview = () => {
  const review = [
    {
      displaypic: "D",
      comment:
        "I’ve been using Techcherry’s software to enhance the operations at my optical shop, and I couldn’t be more satisfied. The software is incredibly easy to use and offers a wide range of features tailored specifically to the needs of an optical shop.What truly sets Techcherry apart is their comprehensive service. I’d like to give a special shoutout to Ms. Shriti for her outstanding support during the installation process. Her professionalism, patience, and helpful attitude made the entire experience seamless.This is a 5-star experience for me, and I highly recommend Techcherry to anyone in need of reliable and feature-rich software solutions.",
      rated: 4.5,
      name: "Dinesh Thakwani",
    },
    {
      displaypic: "S",
      comment:
        "Hello Roshni Mam is Best Service Support .  Keep track of your expenses with ease using YES BM the most efficient accounting OPtical Software",
      rated: 4.7,
      name: "Sridevi Murthy",
    },
    {
      displaypic: "B",
      comment:
        "SHIV PATEL JI THANS FOR YOUR BEST SUPORT FOR SOLVING MY ISSUE . BEST SOFTWARE",
      rated: 5,
      name: "BALAJI GARMENTS",
    },
    {
      displaypic: "S",
      comment:
        "Its a wonderful software with lots of features and technical support.",
      rated: 5,
      name: "Sonu Prakash",
    },
    {
      displaypic: "S",
      comment:
        "Its a wonderful software with lots of features and technical support.",
      rated: 5,
      name: "Sonu Prakash",
    },
  ];

  const settings = {
    dots: false, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in ms
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set the speed of autoplay (in ms)
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
  };

  const Mobilesettings = {
    dots: false, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in ms
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: false, // Enable autoplay
    autoplaySpeed: 2000, // Set the speed of autoplay (in ms)
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
  };

  return (
    <>
      <div className={`review-main-div`}>
        <h3>
          Happy <span>Users</span> Say About Our{" "}
          <span>Software and Services</span>
        </h3>
        <div className={`div-for-slider review-for-desktop`}>
          <Slider className={`slider`} {...settings}>
            {review.map((item, index) => {
              return (
                <>
                  <div className={`item`}>
                    <div className={`name-capital`}>{item.displaypic}</div>

                    <div className={`comment-para`}>
                      <p>{item.comment}</p>
                    </div>

                    <div className={`name-and-star`}>
                      <span className={`name`}>{item.name}</span>
                      <span className={`star`}>
                        <i class="fa-solid fa-star"></i>
                        {item.rated}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>

        {/* ----------For-mobile---------- */}
        <div className={`div-for-slider review-for-mobile`}>
          <Slider className={`slider`} {...Mobilesettings}>
            {review.map((item, index) => {
              return (
                <>
                  <div className={`item`}>
                    <div className={`name-capital`}>{item.displaypic}</div>

                    <div className={`comment-para`}>
                      <p>{item.comment}</p>
                    </div>

                    <div className={`name-and-star`}>
                      <span className={`name`}>{item.name}</span>
                      <span className={`star`}>
                        <i class="fa-solid fa-star"></i>
                        {item.rated}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default UsersReview;
