import React, { useEffect, useState } from 'react'
import "./CSS/footer.css"
import token from '../Config/Token'
import Backend_Url from '../Config/BackendUrl'
import axios from "axios";
import "../../src/Optical/css/Responsive.css"

function Footer() {
  const [sticky, setSticky] = useState(false)

  const [footer, setFooter] = useState([])

  //   const fetchGeneralSetting = async ()=>{
  //     debugger
  //     try{
  //       await axios.get(`${Backend_Url}/GeneralSetting/get/all`,
  //         {
  //             method: 'GET',
  //             headers: {
  //                 'authorization': 'Bearer ' + token
  //             }
  //         }
  //     )
  //         .then((res) => res.json())
  //         .then((res) => setFooter(res))
  //     }
  //     catch(error){
  //    console.log(error)
  //     }
  //  }

  const fetchGeneralSetting = async () => {
    try {
      const response = await axios.get(`${Backend_Url}/GeneralSetting/get/all`, {
        headers: {
          'authorization': 'Bearer ' + token,
        },
      });

      // Assuming the response is in 'data' field
      setFooter(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGeneralSetting();
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setSticky(true)
      }
      else {
        setSticky(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.addEventListener('scroll', handleScroll)

    }
  }, [])

  return (
    <>
      <footer className={`bg-blue-900 text-white py-2  
     
   `}>
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">

          {/* Email and Contact Info */}
          {footer.map((item, index) => (
            <>
              <div className="for-desktop flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
                <a href="mailto:example@example.com" className="hover:text-gray-400 text-[14px]">
                  <i className="fas fa-envelope mr-2"></i>  {item.Email}
                </a>
                <a href="tel:+1234567890" className="hover:text-gray-400 text-[14px]">
                  <i className="fas fa-phone-alt mr-2"></i> {item.Phone}
                </a>
                <a href="/techcherry-optical-software" target='_blank' className="hover:text-gray-400 text-[14px]">
                  <i className="fas fa-arrow-right"></i> Go To www.myopticalapp.com
                </a>

              </div>

              {/* ---------------formobile--------------- */}
              <div className="for-mobile flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
                <a href="mailto:example@example.com" className="hover:text-gray-400 text-[14px]">
                  <i className="fas fa-envelope mr-2"></i>
                </a>
                <a href="tel:+1234567890" className="hover:text-gray-400 text-[14px]">
                  <i className="fas fa-phone-alt mr-2"></i >
                </a>
                <a href="/techcherry-optical-software" target='_blank' className="hover:text-gray-400 text-[14px]">
                  <i className="fas fa-arrow-right"></i>
                </a>

              </div>
            </>
          ))}


          {/* Social Media Icons */}
          <div className="social-icon-mobile flex space-x-4">
            <a href="/" className="hover:text-gray-400 text-[14px]">
              <i className="fas fa-user mr-2"></i>
            </a>
            <a href="/" className="hover:text-gray-400 text-[14px]">
              <i className="fas fa-ticket-alt"></i>
            </a>
            <a href="/" className="hover:text-gray-400">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="/" className="hover:text-gray-400">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="/" className="hover:text-gray-400">
              <i className="fab fa-youtube"></i>
            </a>
            <a href="/" className="hover:text-gray-400">
              <i className="fab fa-facebook"></i>
            </a>
          </div>

        </div>
      </footer>
    </>
  )
}

export default Footer
