import React from "react";
import "./Medical.css";
import { Helmet } from "react-helmet";
import Opticalnav from "../../Optical/Opticalnav";
import Optbiling from "../../Optical/Optbiling";
import Feature from "../../Optical/Optfeature";
import Customer from "../../Optical/Optcustomer";
import Optcard from "../../Optical/Optcard";
import Optform from "../../Optical/Optform";
import Optform1 from "../../Optical/Optform1";
import Optabout from "../../Optical/Optabout";
import MedicalNavbar from "./Navbar/MedicalNavbar";
import MedicalBanner from "./MedicalBanner/MedicalBanner";
import Keyfeatures from "./KeyFeatures/Keyfeatures";
import Middlebanner from "./Middlebanner/Middlebanner";
import Inventory from "../image/Inventory.png";
import Reportingsvg from "../image/Reporting.svg";
import Accounting from "../image/Accounting.svg";

const Medical = () => {
  const inventory = [
    {
      title: "Batch Wise/MRP wise stock",
    },
    {
      title: "Import Item Masters from Excel",
    },
    {
      title: "Import purchase from external file (excel/text)",
    },
    {
      title: "Fully Customized Barcode Generation",
    },
    {
      title: "Expired/Damage Stock Management",
    },
    {
      title: "Bin/Rack wise Items",
    },
    {
      title: "Buy in Strips and Sell in Tablets (Multiple units of conversion)",
    },
  ];

  const Reporting = [
    {
      title: "Pre-Configured reporting template",
    },
    {
      title: "Configurable reports and dashboards",
    },
    {
      title: "Mobile Reporting App",
    },
  ];

  const Accounting_and_fiance = [
    {
      title: "Simple and Configurable Accounting Module",
    },
    {
      title: "Journal Entries, Cash/Bank Vouchers",
    },
    {
      title: "Bills Receivables/Payables",
    },
    {
      title: "Cost Centers",
    },
    {
      title: "Balance Sheet, P&L, Ratio Analysis",
    },
  ];

  return (
    <>
      <div>
        <Helmet>
          {" "}
          {/* used for make a sco friendly */}
          <title>
            mymedicalcrm.in - Increase Your Pharmacy's Profits with Smart
            Inventory Management
          </title>
          <meta
            name="description"
            content="Say goodbye to stockouts and expired medications. mymedicalcrm provides real-time inventory tracking, automated stock alerts, and powerful reporting to help you optimize stock levels, minimize wastage, and maximize profitability. Improve your pharmacy's efficiency and customer satisfaction with our intelligent inventory management solution."
          />
          <meta
            name="keywords"
            content="Medical store billing software, Pharmacy management software, Inventory management for pharmacy, Prescription management, Stock control for medical stores, POS for pharmacy, Customer relationship management (CRM) for pharmacies, Healthcare software"
          />
          <link rel="canonical" href="https://yourwebsite.com/" />
          {/* Open Graph and Twitter Meta Tags for Social Sharing */}
          <meta
            property="og:title"
            content="Optical Shop Software | Optical Store Billing software | YES Optical Management Software : Techcherry"
          />
          <meta
            property="og:description"
            content="YES-OPTICAL is a complete Point of Sale (POS) Solution for Optical shop. Our core focus is “Our Client’s” and we use that to guide our day-to-day business decisions. Try us first before......"
          />
          <meta
            property="og:image"
            content="https://yourwebsite.com/thumbnail.jpg"
          />
          <meta property="og:url" content="https://yourwebsite.com/" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <MedicalNavbar />
        <MedicalBanner />
        <Keyfeatures />
        <Middlebanner />

        <Middlebanner
          title="Inventory Management"
          image={Inventory}
          fetures={inventory}
          reverse={true}
        />
        <Middlebanner
          reverse={false}
          title="Reporting"
          image={Reportingsvg}
          fetures={Reporting}
        />

        <Middlebanner
          reverse={true}
          title="Accounting & Management"
          image={Accounting}
          fetures={Accounting_and_fiance}
        />

        <Optcard />
        {/* <Optform /> */}
        <Optform1 />
        {/* <Optvideo /> */}
        <Optabout />
      </div>
    </>
  );
};

export default Medical;
