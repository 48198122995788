import React from 'react'
import Footer from '../Footer'
import Footer1 from '../Footer1'
import About from "../About"
import "./dropdownCSS/besterp.css"
import college from "./image/college.jpg"
import mandir from "./image/mandir.jpg"
import student from "./image/student.png"
import people from "./image/people.png"
import phone from "./image/people.png"
import Navbar from '../Navbar'
import admin from "./image/admin.png";
import admission from "./image/admission.png"
import fee from "./image/fee.jpg"
import library from "./image/library.png"
import payroll from "./image/payroll.png"
import visito from "./image/visitor.png"
import transport from "./image/transport.png"
import hostel from "./image/hoestel.png"
import store from "./image/store.png"
import faculty from "./image/faculty.png"
import sms from "./image/SMS.png"
import { Helmet } from 'react-helmet'



function Besterp() {
  
     document.title="software development,mobile app development,seo services @ techcherry.in<"
    const hostpital =[
        {id:1, img:<img src={admin} alt=''/>, text:"Admin"},
        {id:2, img:<img src={admission} alt=''/>, text:"Admission"},
        {id:3, img:<img src={student} alt=''/>, text:"Student"},
        {id:4, img:<img src={fee} alt=''/>, text:"Fee"},
        {id:5, img:<img src={library} alt=''/>, text:"Librqary"},
        {id:6, img:<img src={payroll} alt=''/>, text:"Payroll"},
        {id:7, img:<img src={visito} alt=''/>, text:"Visitort"},
        {id:8, img:<img src={transport} alt=''/>, text:"Transport"},
        {id:8, img:<img src={hostel} alt=''/>, text:"Hostel"},
        {id:8, img:<img src={store} alt=''/>, text:"Store"},
        {id:8, img:<img src={faculty} alt=''/>, text:"Faculty"},
        {id:8, img:<img src={sms} alt=''/>, text:"SMS"},


    ]
  return (
    <div>

<Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>software development,mobile app development,seo services @ techcherry.in</title>
        <meta name="description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta name="keywords" content=" Products, Services, Tech Solutions, Custom Software , Restaurant, Optical Software" />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="software development,mobile app development,seo services @ techcherry.in" />
        <meta property="og:description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      
        <Footer/>
     <Navbar/>
        <div className='besterp'>
          <div className='school'>
            <h3>Why YES College ERP</h3>
            <hr />
            <img src={college} alt="" />

            <p>YES COLLEGE ERP Suite allows you to store, modify and retrieve information using the pull down menus masters, admission, information, examination, promotion, fee management, financial accounting, time table, staff & payroll, library management, inventory, transport and other utilities. Each of the modules has sub modules within them. All these modules are integrated with the information provided from the student and the staff administration module.</p>
          </div>
          <div className='Benefits'>
            <h3>Benefits to Various User Sections</h3>
            <hr />
            <div className="benefit1">
                <div className='benefit-card'>
                  <img src={mandir}alt="" />
                  <h4>Benefits of College & Univercity</h4>
                </div>
                <div className='benefit-card'>
                  <img src={student}alt="" />
                  <h4>Benefits of Student & Teachers</h4>
                </div>
                <div className='benefit-card'>
                  <img src={people}alt="" />
                  <h4>Benifits of Parents</h4>
                </div>
                <div className='benefit-card'>
                  <img src={phone}alt="" />
                  <h4>Technical Benefits</h4>
                </div>
            </div>
          </div>
        </div>

        <div className='Erp-Feature'>
            <h2>KEY Features of ERP</h2>
            <hr />
        <ul>
            <li>YES College ERP Online Suite is server based college based management software.Provides Password facility for different users to ensure high level of security.</li>
            <li>Single database management system</li>
            <li>It is multi user, multi session and multi board suite package</li>
            <li>It is completely user friendly college management software.</li>
            <li>Full fledged Library Management System.</li>
            <li>Generates different types of reports.</li>
            <li>Developed using optimized designing and coding to maintain proper speed.</li>
            <li>Proper and systematic training to different departments.</li>
            <li>Ultimately maintains efficient management.</li>
            <li>YES College Online ERP gives a web enabled interface to the users ie. Students, Faculty, Administrators and Parents.</li>
        </ul>
        </div>

        <div className="ERP-highlight">
            <h2>YES ERP highlights</h2>
            <hr />
            <ul>
                <li>Fully compatible for integration with Bio-metric Devices, Bar-codes, RF-ID’s & Smart-cards</li>
                <li>Manual effort is reduced and speedy and accurate reports are generated.</li>
                <li>Data security is maintained on a departmental level. High degree of security and safety according to international standards.</li>
                <li>High degree of transparency of the institution through the Web.</li>
                <li>Better co-ordination between departments.</li>
                <li>Student conveniences extended beyond the classroom.</li>
                <li>Overall reduction of operating costs; and savings on time and efforts.</li>
                <li>Easy to learn, use and implement.</li>
                <li>Highly streamlined and effective workflow of administration and academia.</li>
                <li>Ensures greater efficiency.</li>
                <li>Unlimited number of users.</li>
                <li>Easy to customize.</li>
            </ul>
        </div>

        <div className="hospital">
        { hostpital.map((item, index) => (
    <div className="admin" key={index}>
       {item.img}
      <h4>{item.text}</h4>
    </div>
         ))}
           
        </div>
        <About/>
        <Footer1/>
    </div>
  )
}

export default Besterp
