import React from 'react'
import "./css/video.css"

function Video() {
  return (
    <>
       <div className='demo-vedio' id='demo'>
          <h1> Watch This Video For Live Experience.</h1>
           <p>Goto Video Tutorial</p>
         <div className='video'>
               <div className='demo1'>
                <h2>English Demo Video</h2>
               <iframe width="414" height="200" src="https://www.youtube.com/embed/PgO-p4EM8Sk" title="YES-BM ENGLISH DEMO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
               </div>
                <div className='demo2'>
                    <h2>  Hindi Demo Video</h2>
                <iframe width="414" height="200" src="https://www.youtube.com/embed/jopMkbv2w9k?si=uWhshDxeFlc-aQFq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
          </div>
      </div>
    </>
  )
}

export default Video