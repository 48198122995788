import React, { useEffect, useState } from "react";
import "./EducationBanner.css";
import bimage from "../../image/BannerFirstimage.webp";
import bimage2 from "../../image/bannerimage2.png";
import right_right1 from "../../image/heroicon2.png";
import right_right2 from "../../image/heroicon3.png";
import spanforgraph from "../../image/heroicon4.png";

const EducationBanner = () => {
  const [hightlight, sethightlight] = useState({
    animation: "one",
    text: "Student information",
  });

  const allhightlights = [
    {
      animation: "one",
      text: "Student information",
    },
    {
      animation: "two",
      text: "Parent - teacher communication",
    },
    {
      animation: "three",
      text: "Classroom management",
    },
    {
      animation: "four",
      text: "Accounts management",
    },
    {
      animation: "five",
      text: "Many more",
    },
  ];

  setTimeout(() => {
    sethightlight((prev) => {
      const currentindex = allhightlights.findIndex(
        (value) => value.text == prev.text
      );
      let nextindex;
      // if (currentindex != 0 && currentindex != allhightlights.length) {
      //   nextindex = currentindex + 1;
      // }
      if (currentindex >= 0 && currentindex !== allhightlights.length - 1) {
        nextindex = currentindex + 1;
      }

      if (currentindex == allhightlights.length - 1) {
        nextindex = 0;
      }

      return allhightlights[nextindex];
    });
  }, 5000);

  return (
    <div className={`education-banner`}>
      {/* --------------------left----------------- */}
      <div className={`education-left`}>
        <span>All in one school management software</span>

        <h2>
          A unified system for Streamlined process for{" "}
          <span className={`${hightlight.animation}`}>{hightlight.text}</span>.
        </h2>

        <p>
          All-in-one school management software for streamlined operations and
          enhanced communication.
        </p>

        <div className={`get-started-education`}>
          <button>Get started</button>
        </div>
      </div>

      {/* right */}
      <div className={`education-right`}>
        {/* ------first--------- */}
        <div className={`image-text`}>
          <div className={`video`}>
            <iframe
              width="100%"
              height="100%"
              //src="https://www.youtube.com/embed/LvMYHRqZg40?autoplay=true"
              src="https://www.youtube.com/embed/aQyy0DaRe98?si=UdG_ubVhQu9zw9iD"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          {/* <img src={bimage} /> */}

          {/* ----secont------ */}
          {/* <div className={`attendance-track`}>
            <img src={bimage2} />
            <div className={`attendance-text`}>
              <h3>Track Attendance</h3>
              <p>Get real-time insights</p>
            </div>
          </div> */}

        </div>

        {/* <div className={`right-right`}>
          <span className={`for-image`}>
            <img src={right_right1} />
            <img src={right_right2} />
          </span>

          <div className={`right-right-text flex items-center justify-center`}>
            <span>
              <img src={spanforgraph} />
            </span>
            <div className={`text`}>
              <h4>Track Attendance</h4>
              <p>Get real-time Insights</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EducationBanner;
