import React from 'react'
import "./css/customer.css"
function Customer() {
  return (
    <>
    <div className='customer'>
       <div className='customer1'>
        <h1>Create, Customize and Print
       <span className='text-amber-600'> Prescription/ non PrescriptionBased GST </span>or <span className='text-amber-600'> Non-GST Bills </span></h1>
       <div className='customer1-sub'> 
         <h5 className='bg-gray-300'>Add Custom fields such as Payment QR code, multiple payment mode etc</h5>
        <p className='bg-gray-300'> <span className='text-amber-500 text-xl'> - </span>Choose your own invoice formats</p>
        <p className='bg-gray-300'> <span className='text-amber-500 text-xl'> - </span>Print according to your needs</p>
        <p className='bg-gray-300'> <span className='text-amber-500 text-xl'> - </span>Regular printer supported (A4 & A5)</p>
        <p className='bg-gray-300'> <span className='text-amber-500 text-xl'> - </span>Thermal Printer supported (2 inch & 3 inch)</p>
       </div>
       </div>
       <div className='customer2'> 
      <img src="https://myopticalapp.com/loginpage/common_assets/images/business_app/Capture_27_1.png" alt="" />
       </div>
    </div>
    </>
  )
}

export default Customer