// import React, { useState } from 'react'
// import Navbar from './Navbar'
// import Footer from './Footer'
// import  "./CSS/price.css"
// import Slider from 'react-slick'
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

// function Pricing() {
    
     
//           const [visiblePrice, setVisiblePrice] = useState('normal'); // Track which price container is visible

//   const handleClick = (section) => {
//     setVisiblePrice(section);
//   };


// const normal =[
//     {id:1, header:'Basic Edition ₹ 8991/',text1:"( Limited Edition )", text2:"18% GST Extra" ,text3:"1 User Full Rights", text4:"₹ 3000/- per extra user",text5:"Maximum 2 users"},
//     {id:2, header:'Silver Edition ₹ 12600/', text2:"18% GST Extra" ,text3:"1 User Full Rights", text4:"1 User for View only",text5:"₹ 3000/- per extra user"},
//     {id:3, header:'Basic Edition ₹ 8991/', text2:"18% GST Extra" ,text3:"Unlimited Users"}

// ]
    
   
//   return (
//     <div>
//         <Footer/>
//       <Navbar/>

//       <div>
//      <div className="price-cont">
//             <h2>Simple & Affordable Pricing</h2>
        

//             <div className='Uper-price'>
//                <ul className='uper-price-ul'>
//                 <li onClick={()=>handleClick('normal')}>Normal </li>
//                 <li  onClick={()=>handleClick('middle')}>middle</li>
//                 <li  onClick={()=>handleClick('advance')}>Adavance</li>

//                </ul>
//              </div>
//       {visiblePrice === 'normal' &&(
//         <div className="price" >
//             {normal.map((item , index)=>(
//                 <div className="price-box1">
//               <div className="price-box1-head">
//                 <h3>{item.header}</h3>
//               </div>
//               <div className="price-box1-contant">
//                 <div className='para-contant0'>
//                     <p className='p1'>{item.text1}</p>
//                      <p className='p2'>{item.text2}</p>
//                 </div>
//                 <div className='para-contant1'> 
//                   <p className='p1'>{item.text3}</p>
//                   <p className='p2'>{item.text4}</p>
//                   <p className='p3'>{item.text5}</p>
//                 </div>
//               </div>
//            </div>
//             ))}
//             </div>
//             )}
           

//            {visiblePrice === 'middle' &&(
//         <div className="price" >
//             {normal.map((item , index)=>(
//                 <div className="price-box1">
//               <div className="price-box1-head">
//                 <h3>{item.header}</h3>
//               </div>
//               <div className="price-box1-contant">
//                 <div className='para-contant0'>
//                     <p className='p1'>{item.text1}</p>
//                      <p className='p2'>{item.text2}</p>
//                 </div>
//                 <div className='para-contant1'> 
//                   <p className='p1'>{item.text3}</p>
//                   <p className='p2'>{item.text4}</p>
//                   <p className='p3'>{item.text5}</p>
//                 </div>
//               </div>
//            </div>
//             ))}
//             </div>
//             )}

//            {/* <div className="price-box1">
//               <div className="price-box1-head">
//                 <h3>Silver Edition ₹ 12600//</h3>
//               </div>
//               <div className="price-box1-contant">
//               <div className='para-contant2'>
//                 <p className='p1'>18% GST Extra</p>     
//               </div>
//               <div className='para-contant3'>
//                   <p className='p1'>1 User Full Rights</p>
//                   <p className='p2'>1 User for View only</p>
//                   <p className='p3'>₹ 3000/- per extra user</p>
//               </div>
//               </div>
//            </div> */}

//            {/* <div className="price-box1">
//               <div className="price-box1-head">
//                 <h3>Gold Edition ₹ 25200///</h3>
//               </div>
//               <div className="price-box1-contant">
//                 <div className='para-contant4'>
//                   <p className='p1'>18% GST Extra</p>     
//                 </div>
//                 <div className='para-contant5'>
//                     <p className='p1'>Unlimited Users</p>
//                     <p ></p>
//                     <p></p>
//                </div>
//               </div>
//            </div> */}
//         {/* </div> */}
       

//            <div className='price-box-down'>
//             <p className='p1'>Final Prices vary on customer requirements and implementation basis.</p>
//             <p className='p2'>*Customisation extra  |   We do not sell through online portals. No Service/support is available if you buy Marg ERP from these sources.</p>
//            </div>

//             <div className="price-middle">
//             <h2>Switch to Smart Inventory & Accounting Management. Switch to Marg® ERP</h2>
//            </div>

//            <div className="price-down">
//             <div className='uper' >
//             <h2> Inventory & Accounting Software</h2>
//             </div>
//             <div className="Lower">
//               <input type="text" placeholder='mobile'/>
//               <input type="text" placeholder='pin code'/>
//               <input type="text" placeholder='name' />
//               <button>BOOK NOW</button>
//             </div>

//            </div>     
//      </div>
  
//     </div>
//     </div>
//   )
// }

// export default Pricing




import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './CSS/price.css';
import About from './About';
import Footer1 from './Footer1';
import { Helmet } from 'react-helmet';

function Pricing() {
  const [visiblePrice, setVisiblePrice] = useState('normal'); // Track which price container is visible
  const[formData, setFormData] = useState({
    name:"",
    phone:"",
    city:""
  })

  const handleClick = (section) => {
    setVisiblePrice(section);
  };

  const handleInputChange =(e)=>{
    const {name, value}= e.target;
    setFormData((prevData)=>({
        ...prevData,
        [name]:value,
    }));
  }

  const handleWhatsAppRedirect =()=>{
    const {name , phone , city} = formData;
  

  const whatsappURL = `https://wa.me/6268941978?text=${encodeURIComponent(
    `Name: ${name}\nPhone: ${phone}\nCity: ${city}`
  )}`;

  window.open(whatsappURL, '_blank');
  }

   const normal =[
    {id:1, header:'Basic Edition ₹ 8991/',text1:"( Limited Edition )", text2:"18% GST Extra" ,text3:"1 User Full Rights", text4:"₹ 3000/- per extra user",text5:"Maximum 2 users"},
     {id:2, header:'Silver Edition ₹ 12600/', text2:"18% GST Extra" ,text3:"1 User Full Rights", text4:"1 User for View only",text5:"₹ 3000/- per extra user"},
     {id:3, header:'Basic Edition ₹ 8991/', text2:"18% GST Extra" ,text3:"Unlimited Users"},
    //  {id:4, header:'Basic Edition ₹ 8991/', text2:"18% GST Extra" ,text3:"Unlimited Users"}
 ]
 const middle =[
    {id:1, header:'Basic Edition ₹ 8993/',text1:"( Limited Edition )", text2:"18% GST Extra" ,text3:"1 User Full Rights", text4:"₹ 3000/- per extra user",text5:"Maximum 2 users"},
     {id:2, header:'Silver Edition ₹ 12600/', text2:"18% GST Extra" ,text3:"1 User Full Rights", text4:"1 User for View only",text5:"₹ 3000/- per extra user"},
     {id:3, header:'Basic Edition ₹ 8991/', text2:"18% GST Extra" ,text3:"Unlimited Users"}
 ]

  return (
    <div>


<Helmet>
        <title>Pricing - My React Website</title>
        <meta name="description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta name="keywords" content="React, Web Development, Products, Services, Tech Solutions, Custom Software" />
        <link rel="canonical" href="https://yourwebsite.com/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="Home - My React Website" />
        <meta property="og:description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Footer />
      <Navbar />

      <div>
        <div className="price-cont">
          <h2>Simple & Affordable Pricing</h2>

          {/* Tabs for selecting price section */}
          <div className="Uper-price">
            <ul className="uper-price-ul">
              <li onClick={() => handleClick('normal')}>Normal</li>
              <li onClick={() => handleClick('middle')}>Middle</li>
              <li onClick={() => handleClick('advance')}>Advance</li>
            </ul>
          </div>

          {/* Price container for 'Normal' */}
          {visiblePrice === 'normal' && (
           <div className="price" >
                        {normal.map((item , index)=>(
                           <div className="price-box1">
                         <div className="price-box1-head">
                           <h3>{item.header}</h3>
                         </div>
                         <div className="price-box1-contant">
                           <div className='para-contant0'>
                               <p className='p1'>{item.text1}</p>
                                <p className='p2'>{item.text2}</p>
                           </div>
                           <div className='para-contant1'> 
                             <p className='p1'>{item.text3}</p>
                             <p className='p2'>{item.text4}</p>
                             <p className='p3'>{item.text5}</p>
                           </div>
                         </div>
                      </div>
                       ))}
            </div>
          )}

          {/* Price container for 'Middle' */}
          {visiblePrice === 'middle' && (
                      <div className="price" >
                        {middle.map((item , index)=>(
                           <div className="price-box1 bg-blue-300">
                         <div className="price-box1-head">
                           <h3>{item.header}</h3>
                         </div>
                         <div className="price-box1-contant">
                           <div className='para-contant0'>
                               <p className='p1'>{item.text1}</p>
                                <p className='p2'>{item.text2}</p>
                           </div>
                           <div className='para-contant1'> 
                             <p className='p1'>{item.text3}</p>
                             <p className='p2'>{item.text4}</p>
                             <p className='p3'>{item.text5}</p>
                           </div>
                         </div>
                      </div>
                       ))}
                       </div>
          )}

          {/* Price container for 'Advance' */}
          {visiblePrice === 'advance' && (
            <div className="price" >
            {middle.map((item , index)=>(
               <div className="price-box1 bg-blue-300">
             <div className="price-box1-head">
               <h3>{item.header}</h3>
             </div>
             <div className="price-box1-contant">
               <div className='para-contant0'>
                   <p className='p1'>{item.text1}</p>
                    <p className='p2'>{item.text2}</p>
               </div>
               <div className='para-contant1'> 
                 <p className='p1'>{item.text3}</p>
                 <p className='p2'>{item.text4}</p>
                 <p className='p3'>{item.text5}</p>
               </div>
             </div>
          </div>
           ))}
           </div>
          )}

          {/* Final Price Information */}
          <div className="price-box-down">
            <p className="p1">
              Final Prices vary on customer requirements and implementation basis.
            </p>
            <p className="p2">
              *Customisation extra | We do not sell through online portals. No Service/support is available if you buy YES-BM from these sources.
            </p>
          </div>

          {/* Middle Section */}
          <div className="price-middle">
             <div className='middle1'>
                <form action="">
                    <input type="text" name="name" placeholder='name'  onChange={handleInputChange}/>
                    <input type="Phone Number" name="phone" placeholder='Phone Number'onChange={handleInputChange} />
                    <input type="City" name="city" placeholder='City' onChange={handleInputChange} />
                </form>
                <hr />
                <div className=' middle2'>
                    <button className='book' onClick={handleWhatsAppRedirect}>Book Now</button>
                </div>
             </div>
          </div>
        </div>
      </div>
      <About/>
      <Footer1/>
    </div>
    
  );
}

export default Pricing;
