import React, { useEffect, useState } from "react";
import "./CSS/Servise.css";
import Backend_Url from "../Config/BackendUrl";
import token from "../Config/Token";
import axios from "axios";
import iconshow from "../Component/image/brand1.png";

function Service() {
  const [BodyCard, setallBodyCard] = useState([]);
  const [icon, setIcon] = useState([]);
  console.log(icon);
  console.log(BodyCard);

  console.log(icon);

  const fetchicon = async () => {
    try {
      await fetch(`${Backend_Url}fontawesome/getAll`, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((data) => setIcon(data.data));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchicon();
  }, []);

  //   const fetchcard = async () => {
  //     await fetch(`${Backend_Url}HPBodyCard/getall`, {
  //         headers: {
  //             'authorization': 'Bearer ' + token
  //         }
  //     })
  //         .then((res) => res.json())
  //         .then((data) => setallBodyCard(data.data))
  //         .catch((err) => window.alert("Can't fetch all body", err))
  // }

  const whatsappclick = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=+919039024004&text=Hello, I'm interested in TechCherry's mobile app!`
    );
  };

  const fetchcard = async () => {
    try {
      const response = await axios.get(`${Backend_Url}/HPBodyCard/getall`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Access the data directly from the response
      const data = response.data;

      if (data && data.data) {
        setallBodyCard(data.data);
        console.log(data);
      } else {
        throw new Error("No data found in response");
      }
    } catch (err) {
      // Handle errors more gracefully
      console.error("Error fetching data:", err);
      window.alert(`Error: ${err.message}`);
    }
  };

  useEffect(() => {
    fetchcard();
  }, []);

  const callclick = () => {
    window.open("tel:919039024004");
  };

  const handleWhatsapp = () => {
    window.location.href = `whatsapp://send/?phone=9039024004&text=Hello Rajesh, I want to Know about your services.`;
  };

  return (
    <>
      <h3 className="Our-service-heading text-4xl text-center justify-center mt-6 text-blue-800 rated-product-name">
        Our Services
      </h3>
      <div className="Service-box">
        {/* <div className='servicebox bg-blue-500  hover:scale-105 duration-200'>
          <i className="fas fa-mobile-alt"></i>
          <p className='text-center'>Customized Mobile & Web App Development</p>
            <div className='Service-sub1'>
              <div className='service-sub1'>
                <p>enquiry now</p>
              </div>
              <div className='service-sub2'>
              <i class="fa-solid fa-square-phone"></i>
              </div>
            </div>
          
        </div> 
        

        <div className='servicebox  bg-orange-400 hover:scale-105 duration-200'>
          <i className="fas fa-shopping-cart"></i>
          <p className='text-center'>Ecommerce Development</p>
          <div className='Service-sub2'>
              <div className='service-sub1'>
                <p>enquiry now</p>
              </div>
              <div className='service-sub2'>
              <i class="fa-solid fa-square-phone"></i>
              </div>
            </div>
        </div>

        <div className='servicebox   bg-blue-500 hover:scale-105 duration-200'>
          <i className="fas fa-eye"></i>
          <p className='text-center'>Opti-Commerce Development</p>
          <div className='Service-sub3'>
              <div className='service-sub1'>
                <p>enquiry now</p>
              </div>
              <div className='service-sub2'>
              <i class="fa-solid fa-square-phone"></i>
              </div>
            </div>
        </div>

        <div className='servicebox  bg-orange-400 hover:scale-105 duration-200'>
          <i className="fas fa-mobile"></i>
          <p  className='text-center'>Brand  Creation</p>
          <div className='Service-sub4'>
              <div className='service-sub1'>
                <p>enquiry now</p>
              </div>
              <div className='service-sub2'>
              <i class="fa-solid fa-square-phone"></i>
              </div>
            </div>
        </div> */}

        {BodyCard.filter((data) => data.Title === "Service").map(
          (service, index) => (
            <div
              key={index}
              className={`servicebox  bg-blue-800 hover:scale-105 duration-200 product-service-clr`}
            >
              {/* Render specific icon based on title */}
              {/* {icon.map((item, iconIndex) => (
              <i key={iconIndex} className={item.FontValue}></i>
            ))} */}
              <div className="product-rated-img w-full h-[80px] !flex !items-center !justify-center">
                {/* <img src={service.CardImage} alt="" /> */}
                {service.Heading === "Web Development" ? (
                  <i
                    style={{ margin: "0px" }}
                    class="fa fa-mobile"
                    id="serIcon"
                  ></i>
                ) : service.Heading === "Ecommerce Development" ? (
                  <i
                    style={{ margin: "0px" }}
                    class="fa fa-shopping-cart"
                    id="serIcon"
                  ></i>
                ) : service.Heading === "Opti-Commerce" ? (
                  <i
                    style={{ margin: "0px" }}
                    class="fa fa-eye"
                    id="serIcon"
                  ></i>
                ) : service.Heading == "Brand Creation" ? (
                  <img className={`!bg-transparent`} src={iconshow} />
                ) : null}
              </div>
              <b>
                <p className="text-center product-heading-name">
                  {service.Heading}
                </p>
              </b>
              <p className="sub-details-name">{service.Details}</p>
              <div className="Service-sub1">
                <div className="service-sub1 cursor-pointer">
                  <button onClick={handleWhatsapp} className="cursor-pointer">
                    Enquiry Now
                  </button>
                </div>
                <div className="service-sub2">
                  <a
                    href={`#`}
                    onClick={() => callclick()}
                    className="fa-solid fa-square-phone"
                  ></a>
                  <a
                    href={`#`}
                    onClick={() => whatsappclick()}
                    className="fa-brands fa-whatsapp"
                  ></a>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default Service;
