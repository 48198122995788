import React from 'react'
import "./ResCss/rescustomer.css"
import restaurant from "./ResImage/resturant.jpeg"

function Rescustomer() {
  return (
    <>
      <div className='rescustomer'>
        <div className='rescustomer1'>
          <h1>Simple  Restaurant
            <span className='text-amber-600'> Billing </span> Software</h1>
          <div className='rescustomer1-sub'>
            <h5 className=''>Add Custom fields such as Payment QR code, multiple payment mode etc</h5>
            <p className=''> <span className='text-amber-500 text-xl'> - </span>Create bills & KOT, receive payments, </p>
            <p className=''> <span className='text-amber-500 text-xl'> - </span>split bills, marge Table</p>
            <p className=''> <span className='text-amber-500 text-xl'> - </span>apply discounts & coupons, configure taxes,</p>

            {/* <p className='bg-gray-300'> <span className='text-amber-500 text-xl'> - </span>Thermal Printer supported (2 inch & 3 inch)</p> */}
          </div>
        </div>
        <div className='rescustomer2'>
          <img src={restaurant} alt="" />
        </div>
      </div>
    </>
  )
}

export default Rescustomer
