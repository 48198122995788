import React, { useState } from "react";
import "./CSS/career.css";
import Footer from "./Footer";
import Navbar from "./Navbar";
import About from "./About";
import Footer1 from "./Footer1";

function UserForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobile: "",
    education: "",
    state: "",
    city: "",
    technology: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    // Here you can send `formData` to your backend server
  };

  return (
    <>
      <Footer />
      <Navbar />

      <div className="Career-container">
        <div className="job-container">
          <div>
            <h1>Immediate Opening for .Net Developer</h1>
            <div className="location">
              <p>durg</p>
              <p>2-3 year+</p>
            </div>

            <div className="job-box">
              <h5>key skills</h5>
              <p>
                Minimum 2 years of Experience in .Net Framework, C#,ASP.Net, In
                depth knowledge and experience with web development technologies
                including ASP.NET, Web API, LINQ, MVC, HTML, HTML5, XML,
                JavaScript, JSON, JQUERY/Angular Js, Bootstrap and CSS , SQL
                Server 2005/ 2008. Well-versed with JavaScript, AJAX, Web
                services, Threading Have strong concepts of OOPS. Database(MS
                SQL) Programming Hands on experience in product/ project
                development using MVC
              </p>
              <h5>Description</h5>
              <p>
                Develop and maintain simple to complex .net applications Deliver
                the quality code, on time Participate in agile development
                process to ensure project deliverables are met, on time and of
                high quality The quality and completeness of assignments,
                including initial design, development, bug fixing, quality
                engineering, documentation, and general product maintenance. To
                understand specification document and perform coding as per the
                design and standards & guide lines which are defined by
                Immediate Lead/Manager. Doing impact analysis and resolving
                in-process problems independently, or with minor input from the
                senior engineering /technical lead. Coordinating with test team
                members for system testing for specialized scripts.
              </p>
            </div>

            <h1>Web Designer - Photoshop/flash/illustrator</h1>
            <div className="location">
              <p>durg</p>
              <p>2-3 year+</p>
            </div>

            <div className="job-box">
              <h5>key skills</h5>
              <p>
                Must have knowledge of Adobe Photoshop, illustrator, Coral draw
              </p>
              <h5>Description</h5>
              <p>
                Designer who can design PSD files in Photoshop, convert them
                into a responsive HTML page for websites & mobile apps. -
                Understanding of Web design trends and techniques and upcoming
                trends - Proficient in Adobe Master Collection CS5 (Photoshop,
                Flash, Illustrator etc.), Macromedia Dreamweaver
              </p>
            </div>
          </div>
        </div>

        <div className="form-container">
          <h2>Apply Here</h2>
          <form className="career-form" onSubmit={handleSubmit}>
            <div className="career-container">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                placeholder="First Name"
              />
            </div>

            <div className="career-container">
              <input
                type="text"
                name="middleName"
                value={formData.middleName}
                onChange={handleChange}
                placeholder="middle Name"
              />
            </div>

            <div className="career-container">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                placeholder="last Name"
              />
            </div>

            <div className="career-container">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Email"
              />
            </div>

            <div className="career-container">
              <input
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
                placeholder="Mobile"
              />
            </div>
            <div className="career-container">
              <input
                type="text"
                name="education"
                value={formData.education}
                onChange={handleChange}
                required
                placeholder="Education"
              />
            </div>

            <div className="career-container">
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
                placeholder="State"
              />
            </div>

            <div className="career-container">
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
                placeholder="city"
              />
            </div>

            <div className="career-container">
              <select
                name="technology"
                value={formData.technology}
                onChange={handleChange}
                required
                placeholder="technology"
              >
                <option value="">Select Technology</option>
                <option value="React">React</option>
                <option value="Vue">Vue</option>
                <option value="Angular">Angular</option>
                <option value="Node.js">Node.js</option>
                <option value="Python">Python</option>
              </select>
            </div>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <About />
      <Footer1 />
    </>
  );
}

export default UserForm;
