import React from 'react'
import "./dropdownCSS/ecommerce.css"
import Navbar from '../Navbar'
import Footer from '../Footer'
import bg from "./image/ecommerce-bg.jpg"
import eco from "./image/eco-img.png"
import ecocard1 from "./image/eco-card1.png"
import ecocard2 from "./image/eco-card2.jpg"
import ecocard3 from "./image/eco-card3.jpg"
import ecocard4 from "./image/eco-card4.png"
import { AbortedDeferredError } from 'react-router-dom'
import About from '../About'
import Footer1 from '../Footer1'
import { Helmet } from 'react-helmet'

function Ecommerce() {
     document.title="TechCherry | Application Designing, Development & Services"
  return (
    <div>

     <Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>TechCherry | Application Designing, Development & Services</title>
        <meta name="description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta name="keywords" content=" Products, Services, Tech Solutions, Custom Software , Restaurant, Optical Software" />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="TechCherry | Application Designing, Development & Services" />
        <meta property="og:description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
        <Footer/>
        <Navbar/>
         
         <div className='Ecom'>
             <img src={bg} alt="" />
             <h1>MAGENTO ECOMMERCE DEVELOPMENT</h1>
         </div>
         <div className='eco-para'>
              Magento Is the Evolution of New Dimension of E-commerce. Magento Solution is a feature-rich, professional open-source Ecommerce Solution that offers merchants complete flexibility and control over the look, content, and functionality of their online store.We specialize in Magento coding standards to produce robust, SEO friendly and flexible Magento Themes, Magento Customization, Magento Designing, Magento Website Maintenance, Custom Module, Extension and Plug-ins Development, Store Customization and Ecommerce SEO Services.
         </div>
         <div className="eco-img">
            <img src={eco} alt="" />
         </div>
         <div className='eco-service'>
            <h1>Our Magento ECommerce  Development Service Offer </h1>
            <div className="eco-card">
                <div className='eco-card1 border'>
                <img src={ecocard1} alt="" />
                 <h3> Custom Theme Development</h3>
                 <p>Here we offer PSD to HTML conversion; Magento theme Integration along with Bootstrap based responsive designs to make it user friendly for all resolutions of web and mobile browsers.</p>
                </div>

                <div className='eco-card1'>
                <img src={ecocard2} alt="" />
                <h3>Customize Plug-in</h3>
                 <p>Customize Plug-in or Extension as per Magento Standards and Framework always needmore attention to achieve client’scustom requirements.</p>
                 </div>
                 
                 <div className='eco-card1'>
                <img src={ecocard3} alt="" />
                <h3>Security</h3>
                <p>Up gradation of your E-commerce store always matters in terms of Security and offering new features towards your customer</p>
                </div>

                <div className="eco-card1 border">
                    <img src={ecocard4} alt="" />
                    <h3>SEO</h3>
                    <p>EO and MaintenanceIn this era of Search Engines like Google, Yahoo, Bing, you always be active on Off-page and On-page SEO of your Ecommerce store.</p>
                </div>

            </div>
         </div>
         <About/>
         <Footer1/>
    </div>
  )
}

export default Ecommerce
