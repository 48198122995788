import React, { useState } from "react";
import "./css/feature.css";
import OrderCreationimg from "../Component/image/feature0.png";
import OrderListimg from "../Component/image/feature1.png";
import CustomerEyeimg from "../Component/image/feature2.png";
import CurrentOrder from "../Component/image/feature3.png";
import MenuList from "../Component/image/feature4.png";
import Cashbook from "../Component/image/feature.png";
import Appdatabook from "../Component/image/feature6.png";

<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css?family=Sofia"
></link>;
function Feature({
  features = [
    "ORDER CREATION",
    "ORDER LIST WITH STATUS",
    "CUSTOMER EYE PRESCRIPTION",
    "CURRENT ORDER STATUS",
    "MENU LIST",
    "CASHBOOK STATUS",
    "APP DATA BACKUP",
  ],
  Images = [
    OrderCreationimg,
    OrderListimg,
    CustomerEyeimg,
    CurrentOrder,
    MenuList,
    Cashbook,
    Appdatabook,
  ],
  ImageType,
}) {
  const [imagestatus, setimagestatus] = useState(0);

  const image = [
    OrderCreationimg,
    OrderListimg,
    CustomerEyeimg,
    CurrentOrder,
    MenuList,
    Cashbook,
    Appdatabook,
  ];

  const [viewmore, setviewmore] = useState(5);

  return (
    <>
      <div className="OPTFEATURE">
        <b>
          <h3 className="text-amber-700">ADVANCE FEATURES</h3>
        </b>
        <div className="optFeature">
          <div className="optfeature1 order-2 md:order-1">
            <ul className="optul">
              {features.slice(0, viewmore).map((item, index) => {
                return (
                  <li onMouseEnter={() => setimagestatus(index)}>
                    {index + 1} {item}
                  </li>
                );
              })}
              <li
                onClick={() =>
                  setviewmore((prev) =>
                    prev == features.length ? 5 : features.length
                  )
                }
                className={` ${
                  features.length > 5 ? "block" : "hidden"
                } hover:!border-b-0 !text-[12px] !font-[300]`}
              >
                View More
              </li>

              {/* <li onMouseEnter={() => setimagestatus(0)}>1) ORDER CREATION</li>
              <li onMouseEnter={() => setimagestatus(1)}>
                2) ORDER LIST WITH STATUS
              </li>
              <li onMouseEnter={() => setimagestatus(2)}>
                3) CUSTOMER EYE PRESCRIPTION
              </li>
              <li onMouseEnter={() => setimagestatus(3)}>
                {" "}
                4) CURRENT ORDER STATUS
              </li>
              <li onMouseEnter={() => setimagestatus(4)}>5) MENU LIST</li>
              <li onMouseEnter={() => setimagestatus(5)}>6) CASHBOOK STATUS</li>
              <li onMouseEnter={() => setimagestatus(6)}>7) APP DATA BACKUP</li> */}
            </ul>
          </div>
          <div
            className={`${
              ImageType == "Optical"
                ? "optical-image"
                : ImageType == "Ophthalmology"
                ? "opthalmology-image"
                : null
            } optfeature2 order-1 md:order-2`}
          >
            <img
              // style={{ width: `${ImageWidth}px` }}
              src={Images[imagestatus]}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Feature;
