import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import "./dropdownCSS/what.css"
import About from '../About'
import Footer1 from '../Footer1'
import { Helmet } from 'react-helmet'

function What() {
   document.title=" >TechCherry | Software Application Development Company in Chhattisgarh "  // for set the title for page
  return (
    <div>
      <Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>TechCherry | Software Application Development Company in Chhattisgarh</title>
        <meta name="description" content="We are a team of designers and usability experts, web developers, and real world marketers. We fuse together to design and build web, social, mobile apps for entrepreneurs, start-ups, mid-sized compan." />
        <meta name="keywords" content=" erp development company in chhattisgarh, erp implementation in chhattisgarh, best software company in chhattisgarh, top software company in bhilai, top software company in raipur." />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="TechCherry | Software Application Development Company in Chhattisgarh" />
        <meta property="og:description" content="We are a team of designers and usability experts, web developers, and real world marketers. We fuse together to design and build web, social, mobile apps for entrepreneurs, start-ups, mid-sized compan" />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Footer/>
      <Navbar/>
      <div>
        <div className="container1 responsive-container">
            <h1>Development Process</h1>
            <p>We are a team of designers and usability experts, web developers, and real world marketers. We fuse together to design and build web, social, mobile apps for entrepreneurs, start-ups, mid-sized companies and enterprises across the world and then make it available to the world with a professional SEO outlook. Working on in-house applications and products ourselves, we test and debug to give you the first hand experience of excellence. We offer wide range of services that exceed your expectations.</p>
            <p>Company offers ERP System(Customized), Office ERP, END to END Business Automation, College/School Management Software; Data warehouse Management System Software, School Management System Software, MLM Software, E-Commerce Software, Job Portal, Real Estate Portal, CRM software and other Website Softwares. Company uses different languages such as Java(Struts 2.0), .Net, Php, joomla, and ASP. Net and Word Press. </p>
            <p >Company is not only dedicated in providing the web softwares to its customers but also provides the SEO & SMO (Search Engine Optimization) service to help it’s customers in gaining the top ranking in the Search Engines like Google, Yahoo, MSN, Bing, etc. Digital Media and Affiliate marketing are also the part of the internet marketing, which helps you in generating the revenue. Marketing the business through the digital media means promoting the product using the digital advertising channels like computing and mobile. Techcherry believes in giving the full satisfactory service to its client. We present help and support and also 100% management for plans by pre-concept in order to continue with strategic marketing and advertising in addition to access.</p>
        </div>
        <div className="container2 responsive-container-2">
            <img src="https://www.techcherry.in/img/development%20process.png" alt="" />
        </div>
        <div className="container3 responsive-container-3 flex-working-img">
            <div className="box">
                <h1>Working Together</h1>
                <p>Our strategy is, to listen hard and working harder thus attaining a flexible approach based on client individuality to seek out the best way for our clients, not just the way. So, we deliver</p>
                <ul>
                <li>Demanded strategies and processes that build value</li>
                <li>Practical and sustainable results by collaborative and responsive approach</li>
                <li>Right technology to meet business objectives</li>
                </ul>
            </div>
            <img src="https://img.freepik.com/free-vector/colleagues-working-together-project_74855-6308.jpg" alt="" />
        </div>
      </div>
      
      <About/>
    </div>
  )
}

export default What
