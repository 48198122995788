import React, { useEffect, useState } from "react";
import "./CSS/techcharry.css";
import Backend_Url from "../Config/BackendUrl";
import token from "../Config/Token";
import axios from "axios";
import thumbail from "../../src/Component/image/videosample.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import VideoModal from "./VideoModal/VideoModal";
import BackgroundImage from "./image/19276.webp";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Techcharry() {
  const [allcontent, setallcontent] = useState([]);
  const [image, setImage] = useState([]);
  const [videomodal, setvideomodal] = useState(false);
  const [videourl, setvodeourl] = useState("");
  console.log(image);

  console.log(allcontent);

  //   const fetdata = async () => {
  //     debugger
  //     try {
  //         const response = await axios.get(`${Backend_Url}/HpContentMaster/getall`, {
  //            headers: {
  //                 'authorization': 'Bearer ' + token
  //             }
  //         })
  //         const data = await response.json();
  //         if(response){
  //           setallcontent(data)
  //         }

  //     } catch (error) {
  //         console.error('Error:', error)
  //     }
  // }

  const fetdata = async () => {
    try {
      const response = await axios.get(
        `${Backend_Url}/HpContentMaster/getall`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );

      // Assuming the response data is in response.data
      if (response.data) {
        setallcontent(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetdata();
  }, []);

  const tabdata = [
    {
      name: "Myopticalapp",
      subheadting: "Optical Shop Management Software",
      tagarray: [
        "Features",
        "Retail& Chain Store",
        "Lens Wholesale",
        "Frame Wholesale",
      ],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      knowmore: "/techcherry-optical-software",
      appname: "Lens Ordering App",
    },
    {
      name: "Ophthalmology",
      subheadting: "Ophthalmology Practice system",
      tagarray: [
        "Features",
        "Ophthalmology Practice",
        "Eye Hospitals",
        "General Practice",
      ],
      knowmore: "/ophthalmology",
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      knowmore: "/ophthalmology",
      appname: "",
    },
    {
      name: "YES-BM",
      subheadting: "Retails Management Software",
      tagarray: [
        "Features",
        "Cloth",
        "Hardware",
        "Footwear",
        "Electrical&Electronics",
      ],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      knowmore: "/retail",
      appname: "Supermarket & Groceries",
    },
    {
      name: "Myrestoapp",
      subheadting: "Restaurant Management system",
      tagarray: [
        "Features",
        "Table Order",
        "Home Delivery& Pickup",
        "Website & APP",
        "POS",
      ],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      knowmore: "/techcherry-restaurant",
      appname: "Supermarket & Groceries",
    },
    {
      name: "My School Campus",
      subheadting: "Institute Management Software",
      tagarray: ["Features", "Schools", "College", "Coching Institutes"],
      url: "https://www.youtube.com/embed/oOS-t-JMs7s?si=3rsIHtmriDu-5gPi",
      thumbail: thumbail,
      knowmore: "/education",
      appname: "",
    },
    {
      name: "Medical",
      subheadting: "",
      tagarray: ["Features", "Retails Medical Store", "Wholesale Distributers"],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      knowmore: "/medical",
      appname: "",
    },
  ];

  const handleVideoclick = (item) => {
    setvideomodal(true);
    setvodeourl(item.url);
  };

  const navigate = useNavigate();
  const handleWhatsapp = () => {
    const phoneNumber = "9039024004"; // Replace with the desired phone number
    const message = "Hello, I would like to know more about your services."; // Optional message

    // Construct the URL to open WhatsApp with a pre-filled message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open WhatsApp (in a new tab or window)
    window.open(whatsappUrl, "_blank");
  };

  const handleCall = () => {
    const phoneNumber = "9039024004";

    const callUrl = `tel:${phoneNumber}`;

    window.location.href = callUrl;
  };
  const Boxes = (item) => {
    return (
      <>
        <div className={`single-box-div`}>
          <div className={`single-box-top`}>
            {/* <h3
              className={`cursor-pointer hover:underline`}
              //onClick={() => window.open(item.knowmore, "_blank")}
              onClick={() => window.location.href = item.knowmore}
            >
              {item.name}
            </h3> */}
            <Link to={item.knowmore} className={`cursor-pointer hover:underline`}>
              <h3>{item.name}</h3>
            </Link>
            <span>{item.subheadting}</span>
          </div>

          <div className={`tag-box-div`}>
            {item.tagarray.map((item, index) => {
              return (
                <div key={index} className="tag-box">
                  <span>{item}</span>
                  <div className={`line`} />
                </div>
              );
            })}
          </div>

          {/* --------video------------- */}
          <div className={`tag-box-video`}>
            <div
              onClick={() => handleVideoclick(item)}
              style={{ backgroundImage: `url(${item.thumbail})` }}
            >
              <span>
                <Icon
                  icon="solar:play-bold"
                  width="30"
                  color="#df0e0e"
                  height="30"
                />
              </span>
            </div>

            {/* <iframe
              width="100%"
              height="180"
              src={item.url}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe> */}
          </div>

          {/* -----clickbtn------- */}
          <div className={`click-btns`}>
            <div>
              <a href={item.knowmore} target="_blank" className={`know-more`}>
                Know More
              </a>
            </div>

            <div>
              <a className={`wtsap`} onClick={() => handleWhatsapp()}>
                whatsapp
                <Icon icon="logos:whatsapp-icon" />
              </a>
              <a className={`call`} onClick={() => handleCall()}>
                call
                <Icon icon="fluent:call-20-filled" />
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      style={{ backgroundImage: `url(${BackgroundImage})` }}
      className="welcome-tech-content"
    >
      <h2 className="text-center text-blue-800 text-4xl tech-title-name">
        Software solutions made easy for{" "}
        <Link to="/techcherry-optical-software"
          className={`optical red`}
        // href="/techcherry-optical-software"
        // target="_blank"
        >
          <spna className={`optical-first `}>O</spna>pticals,
        </Link>{" "}
        <Link to="/ophthalmology" className={`optical green`}
        //href="/ophthalmology" target="_blank"
        >
          <spna className={`optical-first `}>O</spna>phthalmology,
        </Link>{" "}
        <Link to="/retail" className={`retail blue`}
        //target="_blank" href="/retail"
        >
          <spna className={`optical-first `}>R</spna>etails,
        </Link>{" "}
        <Link to="/education" className={`education orange`}
        //target="_blank" href="/education"
        >
          <spna className={`optical-first `}>E</spna>ducations,
        </Link>{" "}
        <Link to="/techcherry-restaurant"
          className={`retrant grey`}
        // target="_blank"
        // href="/techcherry-restaurant"
        >
          <spna className={`optical-first `}>R</spna>estaurants
        </Link>{" "}
        &{" "}
        <Link to="/medical" className={`mediacal yellow`}
        // target="_blank" href="/medical"
        >
          <spna className={`optical-first`}>M</spna>edicals
        </Link>
      </h2>

      {/* <p className='text'>We are a leading IT company in technology, consulting, and next-generation services. We specialize in Customized Solutions & Development in the fields of Software Solutions, Web Based Solutions, Web Enabled ERP Solutions, Web Based Application Development, Mobile Applications, Informative Website Solutions, Dynamic Website Solutions and Websites as our major categories of Business. We are IT Company with our owned Head Office situated at Raipur (C.G.), India with a Technological Dynamic Team of experienced and dedicated professionals consisting of Technical Manager, Team Leaders, Programmers, Designers & Marketing Resources..</p> */}
      {/* <p className='text'>
 
          We are a leading IT company in technology, consulting, and next-generation services. We specialize in Customized Solutions & Development in the fields of Software Solutions, Web Based Solutions, Web Enabled ERP Solutions, Web Based Application Development, Mobile Applications, Informative Website Solutions, Dynamic Website Solutions and Websites as our major categories of Business. We are IT Company with our owned Head Office situated at Raipur (C.G.), India with a Technological Dynamic Team of experienced and dedicated professionals consisting of Technical Manager, Team Leaders, Programmers, Designers & Marketing Resources.
          </p> */}

      {/* ------------create box------------- */}
      <div className={`div-for-box `}>
        {tabdata.map((item, index) => {
          return Boxes(item);
        })}
      </div>

      {videomodal ? (
        <VideoModal close={setvideomodal} videourl={videourl.split("?")[0]} />
      ) : null}
    </div>
  );
}

export default Techcharry;

// import React, { useEffect } from 'react';
// import "./CSS/techcharry.css";

// function Techcharry() {

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             entry.target.classList.add("in-view");
//           } else {
//             entry.target.classList.remove("in-view"); // This will allow the effect to repeat on scroll
//           }
//         });
//       },
//       { threshold: 0.1 } // Adjust threshold as per your needs
//     );

//     // Target elements to observe
//     const leftElement = document.querySelector('.left');
//     const rightElement = document.querySelector('.right');

//     if (leftElement) observer.observe(leftElement);
//     if (rightElement) observer.observe(rightElement);

//     // Cleanup the observer on unmount
//     return () => {
//       if (leftElement) observer.unobserve(leftElement);
//       if (rightElement) observer.unobserve(rightElement);
//     };
//   }, []);

//   return (
//     <div>
//       <h2 className='text-center text-blue-800 text-4xl mt-4 techcharry'>Welcome to Techcherry</h2>
//       <div className="max-w-screen-2xl container mx-auto  px-10 flex flex-col md:flex-row my-5">

//         <div className='w-full order-2 md:order-1 md:w-1/2 left fade-in-left' >
//           <div className='space-y-11'>
//             <h1 className='text-3xl font-bold'></h1>
//             <p className='text'>
//             We are a leading IT company in technology, consulting, and next-generation services. We specialize in Customized Solutions & Development in the fields of Software Solutions, Web Based Solutions, Web Enabled ERP Solutions, Web Based Application Development, Mobile Applications, Informative Website Solutions, Dynamic Website Solutions and Websites as our major categories of Business. We are IT Company with our owned Head Office situated at Raipur (C.G.), India with a Technological Dynamic Team of experienced and dedicated professionals consisting of Technical Manager, Team Leaders, Programmers, Designers & Marketing Resources.
//             </p>
//           </div>
//           <button className="btn mt-6 bg-blue-900 text-white hover:text-black">Read More</button>
//         </div>

//         <div className='banner order-1 w-full md:w-1/2 right fade-in-right'>
//           <img src="https://www.techcherry.in/images/about-techcherry.png" className="w-90 h-90 dark:bg-slate-900" alt="about-techcherry" />
//         </div>

//       </div>
//     </div>
//   );
// }

// export default Techcharry;
