import React, { useState } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import Form1 from "../../Optical/Optform1"
import Optvideo from "../../Optical/Optvideo"
import "./dropdownCSS/stockprice.css"
import defaultImage from '../../Restorant/resto_mob_view/order taker.jpg'; // Default image
import billingImage from '../../Restorant/resto_mob_view/table allocation.jpg'; // Example image for Billing
import onlineOrderImage from '../../Restorant/resto_mob_view/payment.jpg'; // Example image for Online Order
import inventoryImage from '../../Restorant/resto_mob_view/details.jpg'; // Example image for Inventory
import menuImage from '../../Restorant/resto_mob_view/details.jpg'; // Example image for Menu
import reportsImage from '../../Restorant/resto_mob_view/chef.jpg'; // Example image for Reports
import crmImage from '../../Restorant/resto_mob_view/queue.jpg';
import Slider from 'react-slick/lib/slider'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet'

function Stockprice() {
    const [hoveredItem, setHoveredItem] = useState(''); // State to store the hovered item text
    const [hoveredImage, setHoveredImage] = useState(defaultImage); // State for hovered image
  
    // List of feature texts and images
    const featureTexts = [
    //   'Table Allocation',
    //   'Payment Status',
    //   'Order Details',
    //   'Service Status',
    //   'Chef App',
    //   'Queue of Order'
    ];
  
    const featureImages = [
      billingImage,
      onlineOrderImage,
      inventoryImage,
      menuImage,
      reportsImage,
      crmImage
    ];
  
    const attractivefeature=[
        {id:1, img:<img src="https://yes-bm.com/images/business_app/multi%20location%20accessibility.png" alt=''/>, text:"Multi Location Accessibility"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/simplified%20billing2.png" alt=''/>, text:"Simplified billing"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/easily%20manage%20inventory2.png" alt=''/>, text:"Easily Manage Inventory"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/sale%20&%20secure%20inventory2.png" alt=''/>, text:"Sale & Secure Inventory"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Work%20Online_Offline3.jpg" alt=''/>, text:"Work Online/Offline"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Multiple%20Payment%20Options3.png" alt=''/>, text:"Multiple Payment Options"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Business%20Account%20Status1.png" alt=''/>, text:"Business Account Status"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Business%20Reports1.png" alt=''/>, text:"Business Reports"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Manage%20Patient%20Records2.png" alt=''/>, text:"Manage Patient Records"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Alert%20Notifications2.png" alt=''/>, text:"Alert Notifications"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Data%20Synchronization_Security4.png" alt=''/>, text:"Data Synchronization/Security"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Book%20Keeping1.png" alt=''/>, text:"Book Keeping"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Barcode%20Generation%20&%20Scanning1.png" alt=''/>, text:"Barcode Generation & Scanning"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Universal%20Search4.jpg" alt=''/>, text:"Universal Search"},
        {id:1, img:<img src="https://yes-bm.com/images/business_app/Taxation%20with%20Reports1.png" alt=''/>, text:"Taxation with Reports"},

    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        autoplaySpeed: 4000,
        arrows: true,
      };
    
      const slide=[
        {id:1, text:"we love the program and we use software regularly since last 3 year.it is definitely one of the best time saving tool for me." , Author:"- Vision Eye Care", oner:"Shop Owner"},
        {id:2, text:"it is been a lifesaver having YES-BM optical software, in the cloud, online thats accessible.we just found that is a really easy program to use." , Author:"- EYE SQUARE", oner:"MANAGER"},
        {id:3, text:"Wonderful app for offline business...business on your mobile with full inventory and customer data." , Author:"-  Planet Eye-shop Owne", oner:"Owner"},
        {id:4, text:"It is been a lifesaver having YES-BM optical software, in the cloud, online thats accessible.we just found that is a really easy program to use." , Author:"- EYE SQUARE", oner:"Manager"},
        {id:1, text:"we love the program and we use software regularly since last 3 year.it is definitely one of the best time saving tool for me." , Author:"- Vision Eye Care", oner:"Shop Owner"},
        {id:3, text:"Wonderful app for offline business...business on your mobile with full inventory and customer data." , Author:"-  Planet Eye-shop Owne", oner:"Owner"},

      ]
    return (
      <>
       <Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>YES-BM | Software for Optical Store Billing and Stock Inventory</title>
        <meta name="description" content="YES-BM is an optical store Billing and stock inventory software which is available in desktop, cloud based and android APP version. User can Download free trial app from play store.
                Optical Store Software, Optical Shop Software, optical billing software india,optical software,optical retail software,optical shop software,software for optical shop,optical software for retail stores,optical store software,optic software,optical inventory software,software for optical shops,optical software solutions,optical software programs,optical design software open source,optics software,optical software free,free optical software,optical shop software free download,optical software free download,optical billing software,software for optical shop free download,optical store software free download." />
		    <meta name="keywords" content="best free optical billing software, optical billing app, free optical store billing software, cloud based optical software, optical software in india, optical software company in dubai, android app for optical store, optical store software, optical billing pos, optical inventory app." />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="YES-BM | Software for Optical Store Billing and Stock Inventory" />
        <meta property="og:description" content="best free optical billing software, optical billing app, free optical store billing software, cloud based optical software, optical software in india, optical software company in dubai, android app for optical store, optical store software, optical billing pos, optical inventory app." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Footer/>
      <Navbar/>
        <div className='STOCK-PRICE bg-red-800'>
          <b><h3 > <span className='text-white'>ADVANCE</span> FEATURES</h3></b>
          <div className='stock-feature'>
            {/* Feature List */}
            <div className="stock-feature1">
              <ul className='stockul'>
                {['ORDER CREATION', 'ORDER LIST WITH STATUS', 'CUSTOMER WYW PRESCRIPTION', 'CURRENT ORDER STATUC', 'MENU LIST', 'CASHBOOK STATUS'].map((item, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => {
                      setHoveredItem(featureTexts[index]); // Update text on hover
                      setHoveredImage(featureImages[index]); // Update image on hover
                    }}
                    onMouseLeave={() => {
                      setHoveredItem('Order Taker App'); // Reset text on leave
                      setHoveredImage(defaultImage); // Reset image on leave
                    }}
                  >
                    <span><i className="fa-solid fa-arrow-right"></i></span>{item}
                  </li>
                ))}
              </ul>
            </div>
  
            {/* Image and Hover Text */}
            <div className="stock-feature2">
              <img src={hoveredImage} alt="Feature Image" />
              {hoveredItem && <p className='hover-text'>{hoveredItem}</p>} {/* Display hovered text */}
            </div>
          </div>
        </div>

        <div className="attractive">
          <h1>Attractive Feature</h1>
          <div className='attractivefeature'>
          {attractivefeature.map((item,index)=>(
            <div className='stock-card' key={index}> 
               {item.img}
               <h4>{item.text}</h4>
            </div>
          ))}
          </div>
        </div>
       <Form1/>
       <Optvideo/>

       <div className="stock-slide">
        <h1>Testimonials</h1>
        <div className=" stock">
           
         <Slider {...settings} className="">
         {slide.map((item,index)=>(
        <div className=''>
        <h3>{item.text}</h3>
        <h5>{item.Author}</h5>
        <h6>{item.oner}</h6>
             </div>
            ))}
              </Slider>
        </div>
       </div>
      </>
  )
}

export default Stockprice
