// import React from 'react'
// import "./ResCss/resfeature.css"
// import mobile from "./ResImage/Mobile_webisite_Small.png"

// function Resfeature() {
//     return (
//         <>
//         <div className='RESFEATURE'>
//             <b><h3 className="text-amber-700" >ADVANCE FEATUREs</h3></b>
//         <div className='resFeature'>
//            <div className="resfeature1">
//             <ul className='resul'>
//                 <li> <span><i class="fa-solid fa-arrow-right"></i></span>1) Billing </li>
//                 <li>  <span><i class="fa-solid fa-arrow-right"></i></span>2) Online Order</li>
//                 <li>  <span><i class="fa-solid fa-arrow-right"></i></span>3) Inventory</li>
//                 <li> <span><i class="fa-solid fa-arrow-right"></i></span> 4) Menu</li>
//                 <li>  <span><i class="fa-solid fa-arrow-right"></i></span>5) Reports</li>
//                 <li>  <span><i class="fa-solid fa-arrow-right"></i></span>6) CRM</li>
               
//             </ul>
//            </div>
//            <div className="resfeature2">
//             <img src={mobile}alt="" />
//            </div>
//         </div>
//         </div>
//         </>
//       )
// }

// export default Resfeature


// import React, { useState } from 'react';
// import './ResCss/resfeature.css';
// import mobile from './ResImage/Mobile_webisite.jpg';

// function Resfeature() {
//   const [hoveredItem, setHoveredItem] = useState(''); // State to store the hovered item text

//   // List of feature texts and images
//   const featureTexts = [
//     'Manage your billing efficiently.',
//     'Place orders seamlessly online.',
//     'Keep track of your inventory.',
//     'Navigate the user-friendly menu.',
//     'Generate comprehensive reports.',
//     'Enhance customer relations with CRM.'
//   ];

//   return (
//     <>
//       <div className='RESFEATURE'>
//         <b><h3 className="text-amber-700">ADVANCE FEATURES</h3></b>
//         <div className='resFeature'>
//           {/* Feature List */}
//           <div className="resfeature1">
//             <ul className='resul'>
//               {['Billing', 'Online Order', 'Inventory', 'Menu', 'Reports', 'CRM'].map((item, index) => (
//                 <li
//                   key={index}
//                   onMouseEnter={() => setHoveredItem(featureTexts[index])} // Update on hover
//                   onMouseLeave={() => setHoveredItem('')} // Reset on leave
//                 >
//                   <span><i className="fa-solid fa-arrow-right"></i></span>{index + 1}) {item}
//                 </li>
//               ))}
//             </ul>
//           </div>

//           {/* Image and Hover Text */}
//           <div className="resfeature2">
//             <img src={mobile} alt="Mobile Website" />
//             {hoveredItem && <p className='hover-text'>{hoveredItem}</p>} {/* Display hovered text */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Resfeature;


import React, { useState } from 'react';
import './ResCss/resfeature.css';
import defaultImage from './resto_mob_view/order taker.jpg'; // Default image
import billingImage from './resto_mob_view/table allocation.jpg'; // Example image for Billing
import onlineOrderImage from './resto_mob_view/payment.jpg'; // Example image for Online Order
import inventoryImage from './resto_mob_view/details.jpg'; // Example image for Inventory
import menuImage from './resto_mob_view/stuts.jpg'; // Example image for Menu
import reportsImage from './resto_mob_view/chef.jpg'; // Example image for Reports
import crmImage from './resto_mob_view/queue.jpg'; // Example image for CRM

function Resfeature() {
  const [hoveredItem, setHoveredItem] = useState(''); // State to store the hovered item text
  const [hoveredImage, setHoveredImage] = useState(defaultImage); // State for hovered image

  // List of feature texts and images
  const featureTexts = [
    'Table Allocation',
    'Payment Status',
    'Order Details',
    'Service Status',
    'Chef App',
    'Queue of Order'
  ];

  const featureImages = [
    billingImage,
    onlineOrderImage,
    inventoryImage,
    menuImage,
    reportsImage,
    crmImage
  ];

  return (
    <>
      <div className='RESFEATURE'>
        <b><h3 > <span className='text-orange-500'>ADVANCE</span> FEATURES</h3></b>
        <div className='resFeature'>
          {/* Feature List */}
          <div className="resfeature1">
            <ul className='resul'>
              {['Allocate the Table', 'Choose your payment status', 'Complete details about your order based on KOT', 'Allocate Your Order Status', 'Priorities Food through Chef App', 'List or Order based on Order Number'].map((item, index) => (
                <li
                  key={index}
                  onMouseEnter={() => {
                    setHoveredItem(featureTexts[index]); // Update text on hover
                    setHoveredImage(featureImages[index]); // Update image on hover
                  }}
                  onMouseLeave={() => {
                    setHoveredItem('Order Taker App'); // Reset text on leave
                    setHoveredImage(defaultImage); // Reset image on leave
                  }}
                >
                  <span><i className="fa-solid fa-arrow-right"></i></span>{item}
                </li>
              ))}
            </ul>
          </div>

          {/* Image and Hover Text */}
          <div className="resfeature2">
            <img src={hoveredImage} alt="Feature Image" />
            {hoveredItem && <p className='hover-text'>{hoveredItem}</p>} {/* Display hovered text */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Resfeature;


