import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CSS/slide.css"
import Backend_Url from '../Config/BackendUrl';
import token from '../Config/Token';



function Slide() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 4000,
    arrows: false,
  };

  const [slider, setslider] = useState(null);
  console.log(slider);

  const fetchSlider = async () => {
    try {
      const response = await fetch(`${Backend_Url}/api/sliders/all`,
        {
          headers: {
            'authorization': 'Bearer ' + token
          }
        }
      );
      const data = await response.json();
      const images = data.find((item) => item.SliderName == "Sliders")
      console.log("object", data);
      if (response) {
        setslider(images);
      }
    } catch (error) {
      console.error("error fetching data", error);
    }
  };
  useEffect(() => {
    fetchSlider();
  }, []);

  useEffect(() => {
    console.log(slider)
  }, [slider]);

  return (
    //     <div className="relative">


    // <div className="relative slide">
    //       <Slider {...settings} className="carousel w-full ">
    //         <div className="carousel-item  w-full">
    //           <img
    //             src="https://www.techcherry.in/images/1.jpg"
    //             className="w-full h-auto"
    //             alt="Slide 1"
    //           />
    //         </div>
    //         <div className="carousel-item relative w-full">
    //           <img
    //             src="https://www.techcherry.in/images/2.jpg"
    //             className="w-full h-auto"
    //             alt="Slide 2"
    //           />
    //         </div>
    //         <div className="carousel-item relative w-full">
    //           <img
    //             src="https://www.techcherry.in/images/3.jpg"
    //             className="w-full h-auto"
    //             alt="Slide 3"
    //           />
    //         </div>
    //         <div className="carousel-item relative w-full">
    //           <img
    //             src="https://www.techcherry.in/images/first.jpg"
    //             className="w-full h-auto"
    //             alt="Slide 4"
    //           />
    //         </div>
    //         <div className="carousel-item relative w-full">
    //           <img
    //             src="https://www.techcherry.in/images/second.jpg"
    //             className="w-full h-auto"
    //             alt="Slide 5"
    //           />
    //         </div>
    //         <div className="carousel-item relative w-full">
    //           <img
    //             src="https://www.techcherry.in/images/banner1.png"
    //             className="w-full h-auto"
    //             alt="Slide 6"
    //           />
    //         </div>
    //       </Slider>

    //       {/* Custom Navigation Buttons */}

    //     </div>
    //    </div> 

    <div className="relative">
      <div className="relative slide">
        <Slider {...settings} className="carousel overflow-hidden w-full">
          {slider && slider.Imagepaths.length > 0 ? (
            slider.Imagepaths.map((slide, index) => (
              <div key={index} className="carousel-item w-full h-[440px] overflow-hidden !p-0 !m-0 flex">
                <img
                  src={slide}
                  className="w-full h-auto"
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))
          ) : (
            <p>Loading...</p> // Placeholder while fetching data
          )}
        </Slider>
      </div>
    </div>
  );
}

export default Slide;
