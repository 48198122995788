import React, { useState } from 'react'
import "./ResCss/resform1.css"

function Resform1() {
    const [openSection, setOpenSection] = useState(null);

    // Function to toggle dropdown sections
    const toggleDropdown = (section) => {
      setOpenSection(openSection === section ? null : section);
    };
  
    return (
      <div>
        <>
          <div className="Resform">
            <h3 className="text-center text-4xl">
              <span className="text-amber-700"> Frequently </span>Asked Questions
            </h3>
  
            <div className="sub-res-form">
              {/* Section 1 */}
              <div className="resform1">
                <p
                  className=" cursor-pointer"
                  onClick={() => toggleDropdown('what')}
                >
                  <span className="bg-red-200">+</span> How much time it will take to setup my  Techcharry Account?
                </p>
                {openSection === 'what' && (
                  <div className="res-dropdown-content">
                  <li>A: Getting started with Techcherry is super quick! Our onboarding team is speedy, and all it takes is about 120 minutes to set up your account. Just share the info we need, and you’ll be good to go on your Techcherry adventure!.
                  </li>
                  </div>
                )}
              </div>
  
              {/* Section 2 */}
              <div className="resform1">
                <p
                  className="cursor-pointer"
                  onClick={() => toggleDropdown('software')}
                >
                  <span className="bg-red-200">+</span> Q: Can I track my orders in real time?
                </p>
                {openSection === 'software' && (
                  <div className="res-dropdown-content">
                 <li>Absolutely! With Techcharry, you can keep tabs on all your orders in real-time, no matter where you are. Just make sure your outlet has a good internet connection, and you’re all set!</li>
                  </div>
                )}
              </div>
  
              {/* Section 3 */}
              <div className="resoptform1">
                <p
                  className=" cursor-pointer"
                  onClick={() => toggleDropdown('eyeclinic')}
                >
                  <span className="bg-red-200">+</span> Q: Does it required continues internet to punch the bills?
                </p>
                {openSection === 'eyeclinic' && (
                  <div className="res-dropdown-content">
                   <li>No worries! Techcharry uses a cool hybrid technology that works both online and offline. Even if you’re not connected to the internet, your orders are still recorded. Once you get back online, all your unsynced orders will automatically update, ensuring your data stays safe!</li>
                  </div>
                )}
              </div>
  
              {/* Section 4 */}
              <div className="resform1">
                <p
                  className=" cursor-pointer"
                  onClick={() => toggleDropdown('business')}
                >
                  <span className="bg-red-200">+</span> Which printer is best suitable with Techcharry POS?
                </p>
                {openSection === 'business' && (
                  <div className="res-dropdown-content">
                    <li>Techcharry is awesome because it’s built without any hardware hassles! It plays nice with all the printers out there in the market, so you can use it seamlessly without any compatibility issues. How cool is that?.</li>
                    
                  </div>
                )}
              </div>
  
              {/* Section 5 */}
              <div className="resform1">
                <p
                  className=" cursor-pointer"
                  onClick={() => toggleDropdown('solution')}
                >
                  <span className="bg-red-200">+</span> Is my data safe with Techcharry POS?
                </p>
                {openSection === 'solution' && (
                  <div className="res-dropdown-content">
                    <li>Absolutely, and it’s a firm commitment from us! We value transparency and strong work ethics. Your entire data is in good hands and kept safe and secure in the clouds.
                    </li>
                  </div>
                )}
              </div>
  
              {/* Section 6 */}
              <div className="resform1">
                <p
                  className=" cursor-pointer"
                  onClick={() => toggleDropdown('lostData')}
                >
                  <span className="bg-red-200">+</span> Q: What if I am switching from another POS?
                </p>
                {openSection === 'lostData' && (
                  <div className="res-dropdown-content">
                    <li>You’re making one of the best decisions for your life! Trust us, it’s a straightforward process. Just share your menu and the info you want to move forward with, and our onboarding team will take care of everything for you. Easy-peasy!</li>
                  </div>
                )}
              </div>
  
              {/* Section 7 */}
              <div className="resform1">
                <p
                  className=" cursor-pointer"
                  onClick={() => toggleDropdown('manual')}
                >
                  <span className="bg-red-200">+</span> I have an android machine, can I use it for billing?
                </p>
                {openSection === 'manual' && (
                  <div className="res-dropdown-content">
                    <li>Absolutely! Techcherry POS gets along with all operating systems—Windows, iOS, and Android. It’s like a tech-friendly chameleon!
                    </li>
                  </div>
                )}
              </div>
  
              {/* Section 8 */}
              <div className="resform1">
                <p
                  className=" cursor-pointer"
                  onClick={() => toggleDropdown('dataSecurity')}
                >
                  <span className="bg-red-200">+</span>Does Techcherry team help me to upload my menu??
                </p>
                {openSection === 'dataSecurity' && (
                  <div className="res-dropdown-content">
                    <li>It’s on us, and we’d love to help! Whether it’s during onboarding or anytime later, feel free to reach out to our support team. They’ll gladly assist you in updating your menu whenever you need. We’ve got your back!</li>
                  </div>
                )}
              </div>
  
              {/* Section 9 */}
              <div className="resform1">
                <p
                  className=" cursor-pointer"
                  onClick={() => toggleDropdown('cost')}
                >
                  <span className="bg-red-200">+</span> What about support?
                </p>
                {openSection === 'cost' && (
                  <div className="res-dropdown-content">
                    <li>Count on it! Techcherry support is like your 24/7 superhero, always there for you. Day or night, even on holidays and during festivals, our support team is ready to help you and your team. It’s a promise we’re proud to keep!</li>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
    );
}

export default Resform1
