import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import "./dropdownCSS/contact.css"; // Optional: Include a CSS file for styling if needed
import About from "../About";
import Footer1 from "../Footer1";
import { Helmet } from "react-helmet";

function Contact() {
  const content = [
    {
      id: 1,
      img: <i class="fa-solid fa-building"></i>,
      text: "Ground Floor, Armaan Palace, Maharaja Chowk, Adarsh Nagar, Durg (C.G) 491001",
      mobileNo: "07884040055, +91 8871439741, +91 9039024004",
    },
    {
      id: 1,
      img: <i class="fa-solid fa-building"></i>,
      text: "Sector-9, Kamal Vihar, Opp to MMR hospital, V.Y Hospital Chowk, Raipur (C.G) 492004",
      mobileNo: "9039024002",
    },

    {
      id: 5,
      img: <i class="fa-regular fa-envelope"></i>,
      text: "contact@techcherry.in",
    },
  ];

  document.title = " Techcherry About Us ";
  return (
    <div>
      <Helmet>
        {" "}
        {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>Contact | Techcherry</title>
        <meta
          name="description"
          content="admin@techcherry.in, techcherry.software@gmail.com - Quick contact of Techcherry"
        />
        <meta
          name="keywords"
          content=" contact of techcherry, techcherry contact, techcherry phone number, techcherry landline no, techcherry email_id"
        />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="Contact | Techcherry" />
        <meta
          property="og:description"
          content="admin@techcherry.in, techcherry.software@gmail.com - Quick contact of Techcherry"
        />
        <meta
          property="og:image"
          content="https://yourwebsite.com/thumbnail.jpg"
        />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Footer />
      <Navbar />
      <div className="contact-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3720.3169380174904!2d81.291225!3d21.179565!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a293c4647d7d7e3%3A0x1fe4ee9d90e66fd8!2sTechCherry%20%7C%20IT%20Consulting%20%26%20Development!5e0!3m2!1sen!2sus!4v1728035291246!5m2!1sen!2sus"
          width="100%" // Use 100% width for better responsiveness
          height="450"
          style={{ border: 0 }} // React inline styling
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="TechCherry Location" // Add a title attribute for accessibility
        ></iframe>
      </div>

      <form action="" className="contact-form">
        <h1 className="text-orange-400">Contact Us</h1>
        <input type="name" placeholder="Name" />
        <input type="email" placeholder="Email" />
        <input type="subject" placeholder="Subject" />
        <textarea placeholder="*Message" rows="5" required></textarea>
        <button className="bg-blue-900">Submit</button>
      </form>

      <div className="contact-container1 !flex !items-center !justify-center ">
        {content.map((item, index) => (
          <div className="content responsive-container1">
            {item.img}
            <p className="contact-add-ress">{item.text}</p>
            <p className="contact-add-ress">{item.mobileNo}</p>
          </div>
        ))}
      </div>
      <About />
      <Footer1 />
    </div>
  );
}

export default Contact;
