import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import "./dropdownCSS/who.css";
import About from "../About";
import Footer1 from "../Footer1";
import { Helmet } from "react-helmet";
import Service1 from "../image/website/Techcherry-Software-Services.webp";
import consultant from "../image/website/Techcherry-IT-consultation.webp";
import custommize from "../image/website/Customize web application techcherry.webp";
import ecom from "../image/website/eCommerce website.webp";
import digital from "../image/website/Digital marketing techcherry.webp";
import why from "../image/website/why choose.webp"

function Services() {
  document.title = " TechCherry | SoftwareDevelopmen Company in Chhattisgarh ";
  return (
    <div>
      <Helmet>
        {" "}
        {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>TechCherry | Software Development Company in Chhattisgarh</title>
        <meta
          name="description"
          content="Welcome to Techcherry, formally called as Techcherry Software Consultancy Services Pvt. Ltd. Techcherryis 
         operated by leaders who are well experienced and have worked with global leaders of IT, ...."
        />
        <meta
          name="keywords"
          content=" software provider company in chhattisgarh, website provider company in chhattisgarh, website designing 
         company in chhattisgarh, techcherry.in, techcherry in raipur, techcherry in durg, techcherry in bilashpur, software services in raipur."
        />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content="TechCherry | Application Designing, Development & Services"
        />
        <meta
          property="og:description"
          content="Welcome to Techcherry, formally called as Techcherry Software Consultancy Services Pvt. Ltd. Techcherryis 
         operated by leaders who are well experienced and have worked with global leaders of IT, ...."
        />
        <meta
          property="og:image"
          content="https://yourwebsite.com/thumbnail.jpg"
        />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Footer />

      <Navbar />
      <div>
        <div className="container1">
          <img className="Services-container" src={Service1} alt="" />
          {/* <div className="box our-services">
            <h2>Our Services </h2>
            <p className="service-provide">
              Techcherry specializes in providing a comprehensive suite of IT services tailored to meet the unique needs of businesses across various industries. Our expertise encompasses:
            </p>
          </div> */}
        </div>
        <div className="our-service-top">
            <h2 className="header-customize">Our Services </h2>
            <p className="service-provide">
              Techcherry specializes in providing a comprehensive suite of IT services tailored to meet the unique needs of businesses across various industries. Our expertise encompasses:
            </p>
          </div>

        <div className="tech-container2 cosultant-service-top">
          <div className="img flex-consultant">
            <img
              className={`responsive-img consultant-it`}
              src={consultant}
              alt=""
            />
          </div>
          <div className="post">
            <h2>IT Consultation</h2>
            <p>
              Strategic IT Planning: We work closely with clients to understand their business goals and develop a robust IT strategy that aligns with their objectives. This includes needs assessments, technology roadmaps, and recommendations for optimal infrastructure.
            </p>
          </div>
        </div>

        {/* <div className="tech-container3">
          <img
            src="https://www.riskcompliance.biz/wp-content/uploads/2020/08/OPRES-5e870bab74f87-e1597400902725.png"
            alt=""
          />
        </div> */}

        <div className="container4">
          <div className="cont4-post">
            <h2 className="header-customize">Customized Web Application Development</h2>
            <p>
              Bespoke Solutions: We develop custom web applications that are tailored to specific business requirements, providing unique functionalities and seamless integration with existing systems.</p>
            <p>Agile Development Methodology: We employ agile development principles to ensure flexibility, rapid iteration, and client collaboration throughout the development process.</p>
            <p>Technology Expertise: Our team leverages the latest technologies, including .NET core, Java, Python, and React, to create high-performance, user-friendly web applications.</p>

          </div>
          <div className="cont4-img flex-development">
            <img className="customized-img"
              src={custommize}
              alt=""
            />
          </div>
        </div>

        <div className="tech-container2 solution-ecom ">
          <div className="img flex-consultant">
            <img
              className={`responsive-img consultant-it`}
              src={ecom}
              alt=""
            />
          </div>
          <div className="post">
            <h2>E-commerce Solutions</h2>
            <p>
              Online Store Development: We design and develop robust and visually appealing e-commerce platforms, integrating features such as secure payment gateways, inventory management, and order fulfillment. </p>
            <p>Marketing & Sales Optimization: We help businesses optimize their online stores for search engines (SEO), implement effective marketing campaigns, and drive sales growth.</p>
            <p>Customer Relationship Management (CRM) Integration: We integrate e-commerce platforms with CRM systems to streamline customer interactions, personalize experiences, and improve customer loyalty.</p>


          </div>
        </div>

        <div className="container4">
          <div className="cont4-post">
            <h2 className="header-customize">Digital Marketing</h2>
            <p>
              Search Engine Optimization (SEO): We implement proven SEO strategies to improve search engine rankings, drive organic traffic, and enhance brand visibility. </p>
            <p>Social Media Marketing: We develop and execute effective social media campaigns across various platforms to engage target audiences, build brand awareness, and drive conversions.</p>
            <p> Pay-Per-Click (PPC) Advertising: We manage and optimize PPC campaigns on platforms like Google Ads and social media to maximize ROI and deliver targeted traffic.</p>
            <p>Content Marketing: We create high-quality, engaging content, such as blog posts, articles, and videos, to attract and retain customers, establish thought leadership, and build brand authority.</p>

          </div>
          <div className="cont4-img flex-development">
            <img className="customized-img"
              src={digital}
              alt=""
            />
          </div>
        </div>

        <div className="container5">
          <div className="cont5-img">
            <img className="why-choose-img" src={why} alt="" />
          </div>
          <div className="cont5-post">
            <h1>Why Choose techcherry?</h1>
            <ul>
              <li>Experienced Team: Our team comprises highly skilled and experienced IT professionals with a proven track record of success.</li>
              <li>Client-Centric Approach: We prioritize client satisfaction and build long-term partnerships based on trust and mutual understanding.</li>
              <li>Cutting-Edge Technologies: We leverage the latest technologies and industry best practices to deliver innovative and effective solutions.</li>
              <li>Competitive Pricing: We offer competitive pricing and flexible engagement models to suit the specific needs and budgets of our clients.</li>
              <li>Unlock your business potential with techcherry. Contact us today for a free consultation and let us help you achieve your digital transformation goals.</li>
            </ul>
          </div>
        </div>
      </div>
      <About />
      <Footer1 />
    </div>
  );
}

export default Services;
