import React, { useState } from 'react'
import "./ResCss/resdemo.css"
import call from "./ResImage/call.jpeg"
import { Helmet } from 'react-helmet';
function Resdemo() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        city: '',
        restaurant: ''
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic, such as sending data to backend
        console.log(formData);
      };

      const handleWhatsapp =()=>{
        window.location.replace(`whatsapp://send/?phone=9039024004&text=Hello Rajesh i want to take the demo on your restaurant`)
      }
    
      return (
        <>

      <Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>TechCherry | Application Designing, Development & Services</title>
        <meta name="description" content="Employee First Customer Second with this career vision techcherry will provide good atmosphere to its employee....." />
        <meta name="keywords" content=" software job in bhilai, software job in durg, software job in raipur, software job in chhattisgarh, software job in bilashpur, software development job in chhattisgarh, website designing job in chhattisgarh, job in chhattisgarh, job in raipur, job in bhilai, job in durg, job in software company, php job in chhattisgarh, .net job in chhattisgarh, andriod development job in chhattisgarh, seo job in raipur, seo job in bhilai, seo job in chhattisgarh" />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="Techcherry - My React Website" />
        <meta property="og:description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
        <h3 className='for-responsive-restor text-center text-5xl mt-10'> <span className='text-pink-600'>Free demo</span> by our experts</h3>
        <div className='resDemo'>
            
        <div className="form-container">      
      <form onSubmit={handleSubmit} className="restaurant-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="city">City:</label>
          <input
            type="text"
            id="city"
            name="city"
            placeholder="Enter your city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="restaurant">Restaurant:</label>
          <input
            type="text"
            id="restaurant"
            name="restaurant"
            placeholder="Enter restaurant name"
            value={formData.restaurant}
            onChange={handleChange}
            required
          />
        </div>
     <div className='btn-row'>
        <button type="submit" className="submit-btn">Submit</button>
        <p
        className='chat-btn'
        onClick={handleWhatsapp}
      //  href="https://wa.me/9039024004?text=Hello%20Vaishnavi"
      //  target="_blank"
      //  rel="noopener noreferrer"
        > <span><i className="fa-brands fa-whatsapp  "></i></span> Chat Now</p>
        </div>
      </form>
    </div>
        <div className='resimg'>
            <img src={call} alt="" />
           
        </div>
        </div>
        </>
      );
}

export default Resdemo
