import React from 'react'
import "./css/optabout.css"
import { Icon } from "@iconify/react/dist/iconify.js";

function Optabout() {
   return (
      <>
         <div className='Optabout'>
            <div className='optabout1'>
               <h4>Address</h4>
               <hr />
               <hr />
               <ul>
                  <li className='flex-address-front'>
                     <div className='address-home-icon'> <Icon icon="line-md:home-twotone" width="24" height="24" /></div>
                     <div className='address-main'>Durg Branch - Ground Floor, Armaan Palace, Maharaja Chowk, Adarsh Nagar, Durg (C.G) 491001</div>
                  </li>
                  <li className='flex-address-front'>
                     <div className='address-home-icon'><Icon icon="fluent:call-24-filled" width="24" height="24" /></div>
                     <div className='address-main'>Contact No - 07884040055, +91 8871439741, +91 9039024004</div>
                  </li>
                  <li className='flex-address-front'>
                     <div className='address-home-icon'> <Icon icon="line-md:home-twotone" width="24" height="24" /></div>
                     <div className='address-main'>Raipur Branch - Sector-9, Kamal Vihar, Opp to MMR hospital, V.Y Hospital Chowk, Raipur (C.G) 492004</div>
                  </li>
                  <li className='flex-address-front'>
                     <div className='address-home-icon'><Icon icon="fluent:call-24-filled" width="24" height="24" /></div>
                     <div className='address-main'>Contact No - 9039024002</div>
                  </li>
                  <li className='flex-address-front'>
                     <div className='address-home-icon'><Icon icon="ic:twotone-email" width="24" height="24" /></div>
                     <div className='address-main'>Email Id - contact@techcherry.in</div>
                  </li>
               </ul>
            </div>
            <div className='optabout2'>
               <h4> Quick Link</h4>
               <hr />
               <hr />
               <ul>
                  <li>  Home</li>
                  <li> Why yes-bm</li>
                  <li>Featured product</li>
                  <li> Arichitecture</li>
                  <li>send Enqiry</li>
               </ul>
            </div>
            <div className='optabout3'>
               <h4>PRODUCT</h4>
               <hr />
               <hr />
               <ul>
                  <li> Desktop Apps</li>
                  <li> Web Application</li>
                  <li> Mobile App</li>
                  <li> Online Shopping</li>
               </ul>
            </div>
            <div className='optabout4'>
               <iframe width="300" height="240" src="https://www.youtube.com/embed/6Ioc1tQfCGQ?si=5bbAEaEl-VtbX7zG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
         </div>
      </>
   )
}

export default Optabout
