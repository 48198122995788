import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import "./dropdownCSS/custom.css"
import back from "./image/back.png"
import custom from "./image/service-card.jpg"
import About from '../About'
import Footer1 from '../Footer1'
import { Helmet } from 'react-helmet'

function Constom() {

  return (
    <div>
       <Helmet> {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
        <title>TechCherry | Application Designing, Development & Services</title>
        <meta name="description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta name="keywords" content=" Products, Services, Tech Solutions, Custom Software , Restaurant, Optical Software" />
        <link rel="canonical" href="https://www.techcherry.in/" />
        {/* Open Graph and Twitter Meta Tags for Social Sharing */}
        <meta property="og:title" content="TechCherry | Application Designing, Development & Services" />
        <meta property="og:description" content="Welcome to our React-based website. We offer a wide range of products and services, including custom software development, tech solutions, and more." />
        <meta property="og:image" content="https://yourwebsite.com/thumbnail.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Footer/>
      <Navbar/>
      <div>
        <div className="custom">
            <div className='custom1'>
                <div className="custom-box">
               <h1>Responsive Web App Development</h1>
                <p>HTML 5 is the technology which enables you to develop visually stimulating and optimized mobile applications for every striking device of the Smartphone universe! Tech cherry is now taking the rich tradition of fast technology for further adoption in providing new age solutions to its partners, with the adoption of mobile application development solutions using HTML5, CSS3 & JavaScript. Tech cherry designs and develops multi-platform apps using HTML5, JavaScript and CSS3 to create web apps and native mobile apps. HTML5 multi-platform application development is the fastest cost effective solution for porting your apps from iPhone to Android/ WebOs/ Symbian/ Windows Phone 7/ Blackberry plus. Besides this, we can also develop web apps for browsers like Google Chrome and Firefox simultaneously.</p>
                </div>
            </div>
            <div className='custom2'>
                <img src={back} alt="" />
            </div>
        </div>

        <div className='custom-container'>
            <h1 className='text-blue-800 mt-6 text-[26px]'>Responsive Web App Development process we follow</h1>
        <img src={custom} alt="" />
        </div>
      </div>
      <About/>
      <Footer1/>
    </div>
  )
}

export default Constom
