import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Slide from "../Slide";
import Service from "../Service";
import Product from "../Product";
import Techcharry from "../techcharry";
import Container from "../Container";
import Marquee from "../Marquee";
import About from "../About";
import Footer1 from "../Footer1";
import { Helmet } from "react-helmet";
import "../../Optical/css/Responsive.css";
import Highlited from "../HighlitedIcon/Highlited";
import BackImageAttachment from "../BackAttachmentText/BackImageAttachment";
import BookInPocket from "../BookinPocket/BookInPocket";
import UsersReview from "../UsersReview/UsersReview";
import Frequition from "../Freqution/Frequition";
function Home() {
  return (
    <>
      {" "}
      {/* used for make a sco friendly  which is contain the content of our website which show when we search on google*/}
      {/* <title>TechCherry | Application Designing, Development & Services</title> */}
      {/* <meta name="description" content="Techcherry Software Consultancy Services Pvt. Ltd. is one of the Best Application Designing and Development Company in India with vision to provide best services to its customer." /> */}
      {/* <Helmet>
        <meta
          name="keywords"
          content=" web design and development company in bhilai, web design and development company in durg, web design development company in raipur,web design and development services in india,web design and development company in bilaspur, software company in bhilai, list of software company in chhattisgarh,  top software company in chhattisgarh, application development company in chhattisgarh, software company in raipur, software company in bilashpur, software company in durg, software job in chhattisgarh, software development company in C.G, software services company in chhattisgarh"
        />
        <link rel="canonical" href="https://www.techcherry.in/" />

        <meta property="og:title" content=" " />
        <meta
          property="og:description"
          content="Techcherry Software Consultancy Services Pvt. Ltd. is one of the Best Application Designing and Development Company in India with vision to provide best services to its customer."
        />
        <meta
          property="og:image"
          content="https://yourwebsite.com/thumbnail.jpg"
        />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet> */}
      {/* <Footer /> */}
      <Navbar />
      {/* <Slide /> */}
      <Techcharry />
      <Highlited />
      <BackImageAttachment />
      <BookInPocket />
      <UsersReview />
      {/* <Container /> */}
      {/* <Product /> */}
      <Service />
      <Frequition />
      {/* <Marquee /> */}
      <About />
      <Footer1 />
    </>
  );
}

export default Home;
